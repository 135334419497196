<template>
  <div id="guidePage">
    <a-form
      ref="step1Ref"
      class="ant-round-form material-company-register-form"
      layout="vertical"
      :model="modelForm"
      :hide-required-mark="true"
      @finish="submit"
    >
      <div class="person-info">
        <div class="cp_content_box">
          <div class="fwb fs20">{{ $t('pages_merchant_add_066') }}</div>
          <div class="fs14 mt5">{{ $t('pages_merchant_add_067') }}</div>
          <a-form-item name="customerIdentity" :rules="rulesChange" style="margin-bottom: 0px;">
            <div class="mt10 fwb">
              <span>{{ $t('pages_merchant_062') }}</span>
              <span style="font-size: 12px">{{ $t('pages_merchant_063') }}</span>
            </div>
            <a-checkbox-group
              v-model:value="modelForm.customerIdentity"
              class="df fw aic"
              :disabled="modelForm.customerIdentityDisabled"
              @change="checkboxChange"
            >
              <a-checkbox
                v-if="countryCode === 'CN'"
                value="0"
                :class="modelForm.customerIdentity.includes('0') ? 'checkbox-area selected' : 'checkbox-area'"
              >
                {{ $t('pages_merchant_054') }}
                <div style="margin: 5px 0 0 -24px">{{ $t('pages_merchant_055') }}</div>
              </a-checkbox>
              <a-checkbox
                v-else
                value="1"
                :class="modelForm.customerIdentity.includes('1') ? 'checkbox-area selected' : 'checkbox-area'"
              >
                {{ $t('pages_merchant_056') }}
                <div style="margin: 5px 0 0 -24px">{{ $t('pages_merchant_057') }}</div>
              </a-checkbox>
              <a-checkbox
                value="2"
                :class="modelForm.customerIdentity.includes('2') ? 'checkbox-area selected' : 'checkbox-area'"
              >
                {{ $t('pages_merchant_058') }}
                <div style="margin: 5px 0 0 -24px">{{ $t('pages_merchant_059') }}</div>
              </a-checkbox>
              <a-checkbox
                value="3"
                :class="modelForm.customerIdentity.includes('3') ? 'checkbox-area selected' : 'checkbox-area'"
              >
                {{ $t('pages_merchant_060') }}
                <div style="margin: 5px 0 0 -24px">{{ $t('pages_merchant_061') }}</div>
              </a-checkbox>
            </a-checkbox-group>
            <div v-if="!modelForm.customerIdentityDisabled && refused && modelForm.customerIdentityMessage" class="error-area">
              <exclamation-circle-outlined class="ic-error" />
              <span>{{ modelForm.customerIdentityMessage }}</span>
            </div>
          </a-form-item>
          <a-form-item v-if="modelForm.customerIdentity.includes('3')" name="authorizationPath" :rules="rulesUpload" class="mb10">
            <div class="fwb">{{ $t('pages_merchant_010') }}</div>
            <div class="explain mb10">
              <span>{{ $t('pages_merchant_079') }}</span>
              <a v-if="language === 'en'" @click="downloadTemplate('boardResolution')">{{ $t('pages_merchant_080') }}</a>
              <a v-else @click="downloadTemplate('powerAttorney')">{{ $t('pages_merchant_080') }}</a>
            </div>
            <div class="pi_upload">
              <upload-auto
                ref="uploadAuthRef"
                v-model:file-content="modelForm.authorizationPath"
                accept=".jpg,.jpeg,.png,.pdf,.bmp"
                type="INVITE"
                list-type="picture"
                back-type="array"
                :disabled="modelForm.authorizationPathDisabled"
                :explain="$t('pages_merchant_032')"
                :max-count="5"
              />
            </div>
            <div v-if="!modelForm.authorizationPathDisabled && refused && modelForm.authorizationPathMessage" class="error-area">
              <exclamation-circle-outlined class="ic-error" />
              <span>{{ modelForm.authorizationPathMessage }}</span>
            </div>
          </a-form-item>
          <div v-if="modelForm.customerIdentity.includes('3')" class="mt15">
            <div class="fwb mb10">{{ $t('pages_merchant_add_069') }}</div>
            <certificate-com
              :model="modelForm"
              :countries="countries"
              :country-code="countryCode"
              :is-add="false"
              :is-address="false"
              :name="'customer'"
              @edit="handleEdit"
            />
            <div v-if="!modelForm.customerDisabled && refused && modelForm.customerMessage" class="error-area">
              <exclamation-circle-outlined class="ic-error" />
              <span>{{ modelForm.customerMessage }}</span>
            </div>
          </div>
          <div v-if="countryCode=='CN'" class="mt15">
            <div class="fwb mb10">{{ $t('pages_merchant_add_074') }}</div>
            <certificate-com
              :model="modelForm"
              :countries="countries"
              :country-code="countryCode"
              :is-add="false"
              :name="'legalPerson'"
              @edit="handleEdit"
            />
            <div v-if="!modelForm.legalPersonDisabled && refused && modelForm.legalPersonMessage" class="error-area">
              <exclamation-circle-outlined class="ic-error" />
              <span>{{ modelForm.legalPersonMessage }}</span>
            </div>
          </div>
          <div v-if="!['CN'].includes(countryCode)" class="mt15">
            <div class="fwb mb10">{{ $t('pages_merchant_056') }}</div>
            <certificate-com
              :model="modelForm"
              :countries="countries"
              :country-code="countryCode"
              :is-add="true"
              :name="'directors'"
              @edit="handleEdit"
            />
            <div v-if="!modelForm.directorsDisabled && refused && modelForm.directorsMessage" class="error-area">
              <exclamation-circle-outlined class="ic-error" />
              <span>{{ modelForm.directorsMessage }}</span>
            </div>
          </div>
          <div class="pi_line_box mt20 jusa df aic fw">
            <a-form-item name="isShareholder" :rules="rulesChange" class="df aic" style="margin-bottom: 0px;">
              <span>{{ $t('pages_merchant_add_077') }}</span>
              <a-radio-group v-model:value="modelForm.isShareholder" :disabled="modelForm.shareholdersDisabled" @change="shareholderRadio">
                <a-radio value="1">{{ $t('pages_merchant_add_078') }}</a-radio>
                <a-radio value="0">{{ $t('pages_merchant_add_079') }}</a-radio>
              </a-radio-group>
            </a-form-item>
            <div />
            <a-form-item v-if="modelForm.isShareholder==='1'" name="isLegalShareholder" :rules="rulesChange" class="df aic" style="margin-bottom: 0px;">
              <span v-if="countryCode=='CN'">{{ $t('pages_merchant_054') }}</span><span v-else>{{ $t('pages_merchant_056') }}</span>{{ $t('pages_merchant_add_080') }}
              <a-radio-group v-model:value="modelForm.isLegalShareholder" :disabled="modelForm.shareholdersDisabled" @change="(e)=>LegalRadio(e.target.value)">
                <a-radio value="1">{{ $t('pages_merchant_add_078') }}</a-radio>
                <a-radio value="0">{{ $t('pages_merchant_add_079') }}</a-radio>
              </a-radio-group>
            </a-form-item>
          </div>
          <div v-if="modelForm.isShareholder==='1'" class="mt15">
            <div class="fwb mb10">{{ $t('pages_merchant_add_076') }}</div>
            <certificate-com
              :key="restData"
              :model="modelForm"
              :countries="countries"
              :country-code="countryCode"
              :is-add="true"
              :name="'shareholders'"
              @edit="handleEdit"
            />
            <div v-if="!modelForm.shareholdersDisabled && refused && modelForm.shareholdersMessage" class="error-area">
              <exclamation-circle-outlined class="ic-error" />
              <span>{{ modelForm.shareholdersMessage }}</span>
            </div>
          </div>
          <a-form-item class="taC mt25 pb30">
            <a-button type="info" shape="round" class="minw130 mr10p" @click="lastBut">{{ $t('pages_merchant_add_054') }}</a-button>
            <a-button html-type="submit" type="primary" shape="round" class="minw130">{{ $t('pages_merchant_020') }}</a-button>
          </a-form-item>
        </div>
      </div>
    </a-form>
  </div>
</template>
<script>

import { reactive, toRefs, inject, computed, ref, nextTick } from 'vue'
import UploadAuto from '@/components/upload-auto'
import { _user } from '@/api'
import certificateComponent from './certificate-component.vue'
import i18n from '@/locale'
export default {
  name: 'PersonInfo',
  components: {
    'upload-auto': UploadAuto,
    'certificate-com': certificateComponent
  },
  props: {
    model: {
      type: Object,
      default: () => { return {} }
    },
    countries: {
      type: Array,
      default: () => { return [] }
    },
    countryCode: {
      type: String,
      default: () => { return '' }
    },
    refused: {
      type: Boolean,
      default: () => { return false }
    },
    pageType: {
      type: String,
      default: () => { return 'false' }
    }
  },
  setup(props, ctx) {
    const uploadAuthRef = ref(null)
    const state = reactive({
      themeData: inject('$themeData'),
      language: localStorage.getItem('locale-language') || 'zh',
      rulesBulr: { required: true, message: i18n.global.t('pages_merchant_add_044'), trigger: 'bulr' },
      rulesChange: { required: true, message: i18n.global.t('pages_merchant_add_055'), trigger: 'change' },
      rulesUpload: { required: true, message: i18n.global.t('common_text_007') },
      restData: 0
    })
    state.modelForm = computed(() => {
      return props.model
    })
    nextTick(() => {
      const { authorizationPath } = state.modelForm
      if (state.modelForm.customerIdentity.includes('3') && authorizationPath.length !== 0 && uploadAuthRef.value) uploadAuthRef.value.fileListRefresh(authorizationPath)
    })
    const submit = () => {
      ctx.emit('next', '+')
    }
    const lastBut = () => {
      ctx.emit('next', '-')
    }
    const checkboxChange = (e) => {
      if (e[e.length - 1] === '3') state.modelForm.customerIdentity = ['3']
      else state.modelForm.customerIdentity = state.modelForm.customerIdentity.filter(item => item !== '3')
      // ctx.emit('edit')
      if (props.pageType === 'add') {
        state.modelForm.isShareholder = null
        state.modelForm.isLegalShareholder = null
      }
      if (state.modelForm.customerIdentity.includes('2')) {
        state.modelForm.isShareholder = '1'
      }
      if (state.modelForm.customerIdentity.includes('1') && state.modelForm.customerIdentity.includes('2')) {
        state.modelForm.isLegalShareholder = '1'
        LegalRadio('1')
      }
      if (state.modelForm.customerIdentity.includes('0') && state.modelForm.customerIdentity.includes('2')) {
        state.modelForm.isLegalShareholder = '1'
        LegalRadio('1')
      }
      if (props.pageType === 'add' && !state.modelForm.customerIdentity.includes('2')) {
        state.modelForm.shareholders = []
      }
      if (state.modelForm.customerIdentity.includes('3')) { // 选择以上都是时
        state.modelForm.customer = [{ // 授权人
          collDisable: false,
          certificateType: '1', // 证件类型
          issuancePlace: '', // 签发地
          personName: '', // 姓名
          personId: '', // 证件号码
          addressName: '', // 地址
          dateBirth: '', // 出生日期
          certificateDate: '', // 证件到期 日期
          isLongDate: false, // 是否长期
          cert1: [], // 身份证正面
          cert2: [], // 身份证反面
          passport: [] // 护照
        }]
      } else {
        state.modelForm.customer = []
        state.modelForm.authorizationPath = []
      }
    }
    const handleEdit = (attr, currentIndex) => {
      if (currentIndex != null) {
        state.modelForm[attr] = state.modelForm[attr].filter((item, index) => index !== currentIndex)
      } else {
        state.modelForm[attr].push({
          collDisable: false,
          certificateType: '1', // 证件类型
          issuancePlace: '', // 签发地
          personName: '', // 姓名
          personId: '', // 证件号码
          addressName: '', // 地址
          dateBirth: '', // 出生日期
          certificateDate: '', // 证件到期 日期
          isLongDate: false, // 是否长期
          cert1: [], // 身份证正面
          cert2: [], // 身份证反面
          passport: [], // 护照
          phone: '', // 电话号码
          isAddress: true, // 现居住地址与身份证件住址相同
          addressCountryCode: '', // 现居住地址-国家
          addressName2: ''// 现居住地址-地址
        })
      }
    }
    const downloadTemplate = async (name) => {
      const res = await _user.exportMerchantTemplate({ name })
      if (res.data.succ) window.open(res.data.data)
    }
    const shareholderRadio = (e) => {
      if (e.target.value === '1') {
        // if (props.countryCode === 'CN' && state.modelForm.customerIdentity.includes('3') && state.modelForm.customer[0].personId) {
        //   const isPersonId = state.modelForm.shareholders.find(k => k.personId === state.modelForm.customer[0].personId)
        //   if (!isPersonId) {
        //     state.modelForm.shareholders.unshift(JSON.parse(JSON.stringify(state.modelForm.customer[0])))
        //   }
        // } else if (state.modelForm.shareholders.length === 0) {
        //   handleEdit('shareholders', null)
        // }
      } else {
        state.modelForm.isLegalShareholder = null
        state.modelForm.shareholders = []
      }
      state.restData = Math.round()
    }
    const LegalRadio = (e) => {
      let dataArray = []
      if (state.modelForm.customerIdentity.includes('0') || props.countryCode === 'CN') {
        dataArray = state.modelForm.legalPerson
      } else if (state.modelForm.customerIdentity.includes('1') || props.countryCode !== 'CN') {
        dataArray = state.modelForm.directors
      }
      if (e === '1') {
        dataArray.forEach(el => {
          if (el.personId) {
            const isPersonId = state.modelForm.shareholders.find(k => k.personId === el.personId)
            if (!isPersonId) {
              if (state.modelForm.shareholders.length > 0 && state.modelForm.shareholders[state.modelForm.shareholders.length - 1].personId) {
                state.modelForm.shareholders.push(JSON.parse(JSON.stringify(el)))
              } else {
                state.modelForm.shareholders.unshift(JSON.parse(JSON.stringify(el)))
              }
            }
          }
        })
        const dataIsPersonId = dataArray.filter(el => el.personId)
        if (dataIsPersonId.length === 0 && state.modelForm.shareholders.length === 0) {
          handleEdit('shareholders')
        }
        state.restData = Math.round()
      } else {
        state.modelForm.shareholders = state.modelForm.shareholders.filter(k => !dataArray.some(i => i.personId === k.personId))
      }
    }
    const initInfo = () => {

    }
    initInfo()
    return {
      uploadAuthRef,
      submit,
      lastBut,
      initInfo,
      checkboxChange,
      handleEdit,
      downloadTemplate,
      shareholderRadio,
      LegalRadio,
      ...toRefs(state)
    }
  }
}
</script>
  <style lang="less" scoped>
  .person-info {
      display: flex;
      justify-content: space-around;
      width: 90%;
      margin: auto;
      .checkbox-area {
        display: flex;
        margin: 10px 0 0px 0;
        border: 1px solid #d9d9d9;
        padding: 12px 20px;
        border-radius: 5px;
        cursor: pointer;
        max-width: 300px;
        margin-right: 20px;
        }
    .checkbox-area:hover, .selected {
        border: 1px solid @main-color;
    }
  }
.pi_upload{
  max-width: 420px !important;
  /deep/.ant-upload{
    padding: 2px 0px  5px 0px !important;
  }
  /deep/.ant-upload-img{
    margin-bottom: 1px !important;
  }
  /deep/.ant-upload-hint{
    margin-top: 2px !important;
  }
}
.pi_line_box{
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    padding: 15px 15px;
}

.bi_input{
  border-radius: 5px !important;
  font-size: 15px !important;
  padding-top: 3px;
  padding-bottom: 3px;
  /deep/.ant-select-selector{
    border-radius: 5px !important;
    height: 32px !important;
    line-height: 32px !important;
  }
  /deep/.ant-select-selection-item{
    line-height: 32px !important;
  }
  /deep/ .ant-select-selection-search-input{
    height: 32px !important;
    line-height: 32px !important;
  }
}
.pi_input_box{
    max-width: 510px !important;
    .ant-form-item{
        min-width: 230px !important;
        margin-bottom: 2px !important;
        margin-left: 20px !important;
      /deep/.ant-input-lg{
        font-size: 14px !important;
      }
      /deep/.ant-form-item-label{
        padding-bottom: 2px !important;
      }
      /deep/ .ant-radio-button-wrapper{
        width: 115px;
        text-align: center;
      }
    }
}
@media screen and (max-width: 768px) {
    .pi_input_box{
    max-width: 100% !important;
}
.pi_input_box{
    .ant-form-item{
        min-width: 100% !important;
        margin-bottom: 5px !important;
        margin-left: 0px !important;
    }
    /deep/.ant-radio-button-wrapper{
        width: 150px !important;
        text-align: center;
    }
}
}

.error-area {
  padding: 5px 10px 5px 12px;
    border-radius: 5px;
    color: @error-color;
    background: #fef0f0;
    margin-top: 5px;
    text-align: left;
    word-break: break-word;
    .ic-error {
      font-size: 16px;
      margin-right: 12px;
    }
    }
</style>
