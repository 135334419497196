<template>
  <div>
    <div v-for="(item,index) in modelForm[arrayName]" :key="index" class="df fw aic jusa pi_line_box mt10">
      <div v-if="modelForm[arrayName].length>1 && !modelForm[arrayName+'Disabled']" class="cc_del_box cp" @click="editFun(index)">
        <close-outlined class="cc_icon_del_color" />
      </div>
      <div>
        <a-form-item
          v-if="item.certificateType === '0'"
          :name="[arrayName,index,'cert1']"
          :rules="{ required: true, message: $t('common_text_007') + $t('pages_merchant_075') }"
          class="mb10 pi_upload"
        >
          <upload-auto
            :ref="getUploadRef"
            v-model:file-content="item.cert1"
            accept=".jpg,.jpeg,.png,.pdf,.bmp"
            type="INVITE"
            list-type="picture"
            back-type="array"
            :disabled="modelForm[arrayName+'Disabled']"
            :name-index="refName+'FRef' + index"
            :explain="$t('common_text_007') + $t('pages_merchant_075')"
            :max-count="1"
            @suceefun="successUploadFun(index,'id1')"
          />
        </a-form-item>
        <a-form-item
          v-if="item.certificateType === '0'"
          class="mb5 pi_upload"
          :name="[arrayName,index,'cert2']"
          :rules="{ required: true, message: $t('common_text_007') + $t('pages_merchant_076') }"
        >
          <upload-auto
            :ref="getUploadRef"
            v-model:file-content="item.cert2"
            accept=".jpg,.jpeg,.png,.pdf,.bmp"
            type="INVITE"
            list-type="picture"
            back-type="array"
            :name-index="refName+'BRef' + index"
            :disabled="modelForm[arrayName+'Disabled']"
            :explain="$t('common_text_007') + $t('pages_merchant_076')"
            :max-count="1"
            @suceefun="successUploadFun(index,'id2')"
          />
        </a-form-item>
        <a-form-item
          v-if="item.certificateType === '1'"
          :name="[arrayName,index,'passport']"
          :rules="{ required: true, message: $t('common_text_007') + $t('pages_merchant_074') }"
          class="pi_upload"
        >
          <upload-auto
            :ref="getUploadRef"
            v-model:file-content="item.passport"
            accept=".jpg,.jpeg,.png,.pdf,.bmp"
            type="INVITE"
            list-type="picture"
            back-type="array"
            :name-index="refName+'PRef' + index"
            :disabled="modelForm[arrayName+'Disabled']"
            :explain="$t('common_text_007') + $t('pages_merchant_074')"
            :max-count="1"
            @suceefun="successUploadFun(index,'passport')"
          />
        </a-form-item>
      </div>
      <div class="df aic fw pi_input_box">
        <div v-if="txtSpin.includes(index)" class="ci_spin df aic jusa">
          <div><a-spin /></div>
        </div>
        <a-form-item
          :name="[arrayName,index,'issuancePlace']"
          :label="$t('pages_merchant_071')"
          :rules="rulesChange"
        >
          <a-select
            v-model:value="item.issuancePlace"
            class="cc_input"
            show-search
            size="large"
            option-filter-prop="label"
            :placeholder="$t('common_text_005') + $t('pages_merchant_071')"
            :disabled="modelForm[arrayName+'Disabled']"
            @change="(e)=>parameChange(e,index,'issuancePlace')"
          >
            <a-select-option
              v-for="country in countries"
              :key="country.areaCode"
              :value="country.areaCode"
              :label="language === 'zh' ? country.areaChineseName : country.areaEnglishName"
            >{{ language === 'zh' ? country.areaChineseName : country.areaEnglishName }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item :name="[arrayName,index,'certificateType']" :label="$t('pages_merchant_072')" :rules="rulesChange">
          <a-radio-group v-model:value="item.certificateType" button-style="solid" :disabled="modelForm[arrayName+'Disabled']" @change="(e)=>parameChange(e.target.value,index,'certificateType')">
            <a-radio-button :disabled="!['CN', 'HK'].includes(item.issuancePlace)" value="0" class="radio-area">{{ $t('pages_merchant_073') }}</a-radio-button>
            <a-radio-button value="1" class="radio-area">{{ $t('pages_merchant_074') }}</a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-form-item :name="[arrayName,index,'personName']" :label="$t('pages_merchant_add_070')" :rules="rulesBulr">
          <a-input
            v-model:value="item.personName"
            class="cc_input"
            size="large"
            allow-clear
            :disabled="modelForm[arrayName+'Disabled']"
            :placeholder="$t('pages_merchant_add_044')"
          />
        </a-form-item>
        <a-form-item :name="[arrayName,index,'personId']" :label="$t('pages_merchant_add_071')" :rules="rulesBulr">
          <a-input
            v-model:value="item.personId"
            class="cc_input"
            size="large"
            allow-clear
            :disabled="modelForm[arrayName+'Disabled']"
            :placeholder="$t('pages_merchant_add_044')"
          />
        </a-form-item>
        <a-form-item :name="[arrayName,index,'dateBirth']" :label="$t('pages_merchant_138')" :rules="rulesBulr">
          <a-date-picker
            v-model:value="item.dateBirth"
            class="cc_input"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            :disabled="modelForm[arrayName+'Disabled']"
            @change="(e)=>parameChange(e,index,'dateBirth')"
          />
        </a-form-item>
        <a-form-item :name="[arrayName,index,'certificateDate']" :label="$t('pages_merchant_139')" :rules="rulesBulr">
          <div class="df aic">
            <a-date-picker
              v-model:value="item.certificateDate"
              class="cc_input"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
              :disabled="modelForm[arrayName+'Disabled'] || item.isLongDate"
              @change="(e)=>parameChange(e,index,'certificateDate')"
            />
            <a-form-item-rest>
              <a-checkbox v-model:checked="item.isLongDate" :disabled="modelForm[arrayName+'Disabled']" class="wsn ml10" @change="(e)=>parameChange(e.target.checked?'2099-12-31':'',index,'certificateDate')">{{ $t('pages_merchant_add_073') }}</a-checkbox>
            </a-form-item-rest>
          </div>
        </a-form-item>
        <a-form-item :name="[arrayName,index,'addressName']" :label="$t('pages_merchant_add_072')" :class="!Object.keys(item).includes('phone')?'w100p':''">
          <a-input
            v-model:value="item.addressName"
            class="cc_input"
            size="large"
            allow-clear
            :disabled="modelForm[arrayName+'Disabled']"
            :placeholder="$t('pages_merchant_add_044')"
            @change="(e)=>addressChange(e,index)"
          />
        </a-form-item>
        <a-form-item v-if="Object.keys(item).includes('phone')" :name="[arrayName,index,'phone']" :label="$t('pages_merchant_add_081')" :rules="rulesBulr">
          <a-input
            v-model:value="item.phone"
            class="cc_input"
            size="large"
            allow-clear
            :disabled="modelForm[arrayName+'Disabled']"
            :placeholder="$t('pages_merchant_add_044')"
          />
        </a-form-item>
        <div v-if="Object.keys(item).includes('isAddress')">
          <a-form-item :name="[arrayName,index,'isAddress']" :rules="rulesChange" style="width: 100%;">
            <a-radio-group v-model:value="item.isAddress" :disabled="modelForm[arrayName+'Disabled']" @change="(e)=>addressRadio(e,index)">
              <a-radio :value="true">{{ $t('pages_merchant_add_056') }}</a-radio>
              <a-radio :value="false">{{ $t('pages_merchant_add_057') }}</a-radio>
            </a-radio-group>
          </a-form-item>
        </div>
        <div v-if="item.isAddress===false" class="df aic fw">
          <a-form-item :name="[arrayName,index,'addressCountryCode']" :label="$t('pages_merchant_add_082')" :rules="rulesChange" class="cc_counry_two">
            <a-select
              v-model:value="item.addressCountryCode"
              class="cc_input"
              show-search
              size="large"
              option-filter-prop="label"
              :placeholder="$t('common_text_005')"
              :disabled="modelForm[arrayName+'Disabled']"
              @change="(e)=>parameChange(e,index,'addressCountryCode')"
            >
              <a-select-option
                v-for="country in countries"
                :key="country.areaCode"
                :value="country.areaCode"
                :label="language === 'zh' ? country.areaChineseName : country.areaEnglishName"
              >{{ language === 'zh' ? country.areaChineseName : country.areaEnglishName }}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item :name="[arrayName,index,'addressName2']" :rules="rulesBulr" class="cc_address_two">
            <a-input
              v-model:value="item.addressName2"
              class="cc_input"
              size="large"
              allow-clear
              :disabled="modelForm[arrayName+'Disabled']"
              :placeholder="$t('pages_merchant_add_044')"
            />
          </a-form-item>
        </div>
      </div>
    </div>
    <div v-if="isAdd && !modelForm[arrayName+'Disabled']" class="taC cc_add_box mt10 cp" @click="editFun(null)">
      <plus-outlined class="cc_icon_color" />{{ $t('pages_merchant_add_075') }}
    </div>
  </div>
</template>
<script>

import { reactive, toRefs, inject, computed, nextTick } from 'vue'
import { _user } from '@/api'
import UploadAuto from '@/components/upload-auto-two'
import i18n from '@/locale'
export default {
  name: 'PersonInfo',
  components: {
    'upload-auto': UploadAuto
  },
  props: {
    model: {
      type: Object,
      default: () => { return {} }
    },
    countries: {
      type: Array,
      default: () => { return [] }
    },
    countryCode: {
      type: String,
      default: () => { return '' }
    },
    isAdd: {
      type: Boolean,
      default: () => { return false }
    },
    isAddress: {
      type: Boolean,
      default: () => { return true }
    },
    name: {
      type: String,
      default: () => { '' }
    }
  },
  setup(props, ctx) {
    const state = reactive({
      themeData: inject('$themeData'),
      language: localStorage.getItem('locale-language') || 'zh',
      rulesBulr: { required: true, message: i18n.global.t('pages_merchant_add_044'), trigger: 'bulr' },
      rulesChange: { required: true, message: i18n.global.t('pages_merchant_add_055'), trigger: 'change' },
      rulesUpload: { required: true, message: i18n.global.t('common_text_007') },
      txtSpin: []
    })
    state.modelForm = computed(() => {
      return props.model
    })
    state.arrayName = computed(() => {
      return props.name
    })
    state.refName = computed(() => {
      if (props.name === 'customer') { return 'uploadC' }
      if (props.name === 'legalPerson') { return 'uploadL' }
      if (props.name === 'directors') { return 'uploadD' }
      if (props.name === 'shareholders') { return 'uploadS' }
      return null
    })
    const parameChange = (val, index, name) => {
      state.modelForm[state.arrayName][index][name] = val
      if (name === 'certificateType') {
        state.modelForm[state.arrayName][index].personName = null
        state.modelForm[state.arrayName][index].personId = null
        state.modelForm[state.arrayName][index].addressName = null
        state.modelForm[state.arrayName][index].dateBirth = null
        state.modelForm[state.arrayName][index].certificateDate = null
        state.modelForm[state.arrayName][index].cert1 = []
        state.modelForm[state.arrayName][index].cert2 = []
        state.modelForm[state.arrayName][index].passport = []
        if (state.modelForm[state.arrayName][index].isAddress) { // 现居住地址与身份证件住址相同
          state.modelForm[state.arrayName][index].addressCountryCode = state.modelForm[state.arrayName][index].issuancePlace
          state.modelForm[state.arrayName][index].addressName2 = null
        }
      }
      if (name === 'issuancePlace') {
        if (state.modelForm[state.arrayName][index].isAddress) { // 现居住地址与身份证件住址相同
          state.modelForm[state.arrayName][index].addressCountryCode = state.modelForm[state.arrayName][index].issuancePlace
        }
      }
    }
    const addressChange = (val, index) => {
      if (state.modelForm[state.arrayName][index].isAddress) { // 现居住地址与身份证件住址相同
        state.modelForm[state.arrayName][index].addressCountryCode = state.modelForm[state.arrayName][index].issuancePlace
        state.modelForm[state.arrayName][index].addressName2 = state.modelForm[state.arrayName][index].addressName
      }
    }
    const addressRadio = (e, index) => {
      if (e.target.value) {
        if (state.modelForm[state.arrayName][index].isAddress) { // 现居住地址与身份证件住址相同
          state.modelForm[state.arrayName][index].addressCountryCode = state.modelForm[state.arrayName][index].issuancePlace
          state.modelForm[state.arrayName][index].addressName2 = state.modelForm[state.arrayName][index].addressName
        }
      }
    }
    const getUploadRef = (el) => {
      if (el) state[el.nameIndex] = el
    }
    const editFun = (index) => {
      ctx.emit('edit', props.name, index)
    }
    const certOcrFunCN = async(json, type, index) => { // 身份证OCR - 中国大陆
      let img = null
      if (type === 'id1') {
        img = json.cert1
      } else {
        img = json.cert2
      }
      state.txtSpin.push(index)
      const res = await _user.imgUrlCertOcrCN(img.join(','))
      if (res.data.succ) {
        const info = res.data.data[0]
        if (type === 'id1') {
          state.modelForm[state.arrayName][index].personName = info.name
          state.modelForm[state.arrayName][index].personId = info.number
          state.modelForm[state.arrayName][index].addressName = info.address
          state.modelForm[state.arrayName][index].dateBirth = info.birthday
          if (state.modelForm[state.arrayName][index].isAddress) { // 现居住地址与身份证件住址相同
            state.modelForm[state.arrayName][index].addressCountryCode = state.modelForm[state.arrayName][index].issuancePlace
            state.modelForm[state.arrayName][index].addressName2 = info.address
          }
        } else {
          state.modelForm[state.arrayName][index].certificateDate = info.timeLimit
        }
      }
      state.txtSpin = state.txtSpin.filter(k => k !== index)
    }
    const certOcrFunHK = async(json, type, index) => { // 身份证OCR - 中国香港
      let img = null
      if (type === 'id1') {
        img = json.cert1
      } else {
        img = json.cert2
      }
      state.txtSpin.push(index)
      const res = await _user.imgUrlCertOcrHK(img.join(','))
      if (res.data.succ) {
        const info = res.data.data[0]
        if (type === 'id1') {
          state.modelForm[state.arrayName][index].personName = info.name
          state.modelForm[state.arrayName][index].personId = info.number
          state.modelForm[state.arrayName][index].addressName = info.address
          state.modelForm[state.arrayName][index].dateBirth = info.birthday
          if (state.modelForm[state.arrayName][index].isAddress) { // 现居住地址与身份证件住址相同
            state.modelForm[state.arrayName][index].addressCountryCode = state.modelForm[state.arrayName][index].issuancePlace
            state.modelForm[state.arrayName][index].addressName2 = info.address
          }
        } else {
          state.modelForm[state.arrayName][index].certificateDate = info.timeLimit
        }
      }
      state.txtSpin = state.txtSpin.filter(k => k !== index)
    }
    const passportOcrFun = async(img, index) => { // 护照OCR
      state.txtSpin.push(index)
      const res = await _user.imgUrlPassportOcr(img.join(','))
      if (res.data.succ) {
        const info = res.data.data[0]
        state.modelForm[state.arrayName][index].issuancePlace = info.countryCode
        state.modelForm[state.arrayName][index].personName = info.name
        state.modelForm[state.arrayName][index].personId = info.passportNo
        state.modelForm[state.arrayName][index].addressName = info.passportAddress
        state.modelForm[state.arrayName][index].dateBirth = info.birthday
        state.modelForm[state.arrayName][index].certificateDate = info.timeLimit
        if (state.modelForm[state.arrayName][index].isAddress) { // 现居住地址与身份证件住址相同
          state.modelForm[state.arrayName][index].addressCountryCode = state.modelForm[state.arrayName][index].issuancePlace
          state.modelForm[state.arrayName][index].addressName2 = info.passportAddress
        }
      }
      state.txtSpin = state.txtSpin.filter(k => k !== index)
    }
    const successUploadFun = (index, type) => {
      if (type === 'passport') {
        passportOcrFun(state.modelForm[state.arrayName][index].passport, index)
      } else {
        if (state.modelForm[state.arrayName][index].issuancePlace === 'CN') {
          certOcrFunCN(state.modelForm[state.arrayName][index], type, index)
        } else {
          certOcrFunHK(state.modelForm[state.arrayName][index], type, index)
        }
      }
    }
    nextTick(() => {
      for (let index = 0; index < state.modelForm[state.arrayName].length; index++) {
        loadPath(state.modelForm[state.arrayName][index].certificateType, state.arrayName, index)
      }
    })
    const loadPath = (type, attr, index) => {
      const ref = attr === 'customer' ? 'uploadC' : attr === 'legalPerson' ? 'uploadL' : attr === 'directors' ? 'uploadD' : 'uploadS'
      if (type === '0' && state[`${ref}FRef${index}`] && state.modelForm[attr][index].cert1.length !== 0) {
        state[`${ref}FRef${index}`].fileListRefresh(state.modelForm[attr][index].cert1)
      }
      if (type === '0' && state[`${ref}BRef${index}`] && state.modelForm[attr][index].cert2.length !== 0) {
        state[`${ref}BRef${index}`].fileListRefresh(state.modelForm[attr][index].cert2)
      }
      if (type === '1' && state[`${ref}PRef${index}`] && state.modelForm[attr][index].passport.length !== 0) {
        state[`${ref}PRef${index}`].fileListRefresh(state.modelForm[attr][index].passport)
      }
    }
    return {
      successUploadFun,
      getUploadRef,
      loadPath,
      parameChange,
      addressChange,
      addressRadio,
      editFun,
      certOcrFunCN,
      certOcrFunHK,
      passportOcrFun,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
  .person-info {
      display: flex;
      justify-content: space-around;
      width: 90%;
      margin: auto;
      .checkbox-area {
        display: flex;
        margin: 5px 0 10px 0;
        border: 1px solid #d9d9d9;
        padding: 12px 20px;
        border-radius: 5px;
        cursor: pointer;
        max-width: 300px;
        margin-right: 20px;
        }
    .checkbox-area:hover, .selected {
        border: 1px solid @main-color;
    }
  }
.pi_upload{
  max-width: 400px !important;
  /deep/.ant-upload{
    padding: 2px 0px  5px 0px !important;
  }
  /deep/.ant-upload-img{
    margin-bottom: 1px !important;
  }
  /deep/.ant-upload-hint{
    margin-top: 2px !important;
  }
}
.pi_line_box{
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    padding: 15px 15px;
    position: relative;
}

.cc_input{
  border-radius: 5px !important;
  font-size: 15px !important;
  padding-top: 3px;
  padding-bottom: 3px;
  /deep/.ant-select-selector{
    font-size: 13px !important;
    height: 32px !important;
    line-height: 32px !important;
    border-radius: 5px !important;
  }
  /deep/.ant-select-selection-item{
    line-height: 32px !important;
  }
  /deep/ .ant-select-selection-search-input{
    height: 32px !important;
    line-height: 32px !important;
  }
  /deep/.ant-select-selection-placeholder{
    line-height: 32px !important;
    font-size: 14px !important;
  }
}
.pi_input_box{
    position: relative;
    max-width: 510px !important;
    .ant-form-item{
        min-width: 230px !important;
        margin-bottom: 2px !important;
        margin-left: 20px !important;
      /deep/.ant-input-lg{
        font-size: 14px !important;
      }
      /deep/.ant-form-item-label{
        padding-bottom: 2px !important;
      }
      /deep/ .ant-radio-button-wrapper{
        width: 115px;
        text-align: center;
      }
    }
    .cc_counry_two{
        min-width: 150px !important;
        width: 150px !important;
    }
    .cc_address_two{
        min-width: 310px !important;
        margin-top: 24px !important;
    }
}

.ci_spin{
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 99;
  background-color: rgba(255,255,255,0.4);
}
@media screen and (max-width: 768px) {
    .pi_input_box{
        max-width: 100% !important;
    }
    .pi_input_box{
        .ant-form-item{
            min-width: 100% !important;
            margin-bottom: 5px !important;
            margin-left: 0px !important;
        }
        /deep/.ant-radio-button-wrapper{
            width: 150px !important;
            text-align: center;
        }
        .cc_address_two{
            width: 100%;
            margin-top: 0px !important;
        }
        .cc_counry_two{
            width: 100%;
        }
    }
}
.cc_add_box{
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    padding: 5px 15px;
    font-size: 14px;
}
.cc_icon_color{
    color: @main-color;
}
.cp{
    cursor: pointer;
}
.cc_del_box{
    position: absolute;
    right: -1px;
    top: -1px;
    font-size: 20px;
    z-index: 99;
    height: 35px;
    width: 35px;
    text-align: center;
    line-height: 35px;
}
.cc_icon_del_color{
    color: @error-color;
}
.w100p{
    width: 100% !important;
}
</style>
