<template>
  <div id="guidePage">
    <a-form
      ref="step1Ref"
      class="ant-round-form material-company-register-form"
      layout="vertical"
      :model="modelForm"
      @finish="submit"
    >
      <div class="collection-product">
        <div class="cp_content_box">
          <div class="df fw  jusa">
            <div>
              <div class="fwb fs20">{{ $t('pages_merchant_add_007') }}</div>
              <div class="fs14 mt5">{{ $t('pages_merchant_add_008') }}</div>
              <a-radio-group v-model:value="modelForm.collectionProductSubType" :disabled="modelForm.collectionProductSubTypeDisabled && modelForm.collectionProductTypeDisabled" @change="productTypeChange">
                <div class="df aic">
                  <div class="cp_radio_box">
                    <div class="fwb fs16">{{ $t('pages_merchant_add_009') }}</div>
                    <div class="mt10"><a-radio value="B2B1">{{ $t('pages_merchant_add_011') }}</a-radio></div>
                    <div class="mt10"><a-radio value="B2B2">{{ $t('pages_merchant_add_012') }}</a-radio></div>
                  </div>
                  <div class="cp_radio_box">
                    <div class="fwb fs16">{{ $t('pages_merchant_add_010') }}</div>
                    <div class="mt10"><a-radio value="B2C1">{{ $t('pages_merchant_add_013') }}</a-radio></div>
                    <div class="mt10"><a-radio value="B2C2">{{ $t('pages_merchant_add_014') }}</a-radio></div>
                  </div>
                </div>
              </a-radio-group>
              <div v-if="(!modelForm.collectionProductSubTypeDisabled || !modelForm.collectionProductTypeDisabled) && refused && (modelForm.collectionProductTypeMessage||modelForm.collectionProductSubTypeMessage)" class="error-area df aic">
                <exclamation-circle-outlined class="ic-error" />
                <div>
                  <div v-if="modelForm.collectionProductTypeMessage">{{ modelForm.collectionProductTypeMessage }}</div>
                  <div v-if="modelForm.collectionProductSubTypeMessage">{{ modelForm.collectionProductSubTypeMessage }}</div>
                </div>
              </div>
              <a-form-item name="countryCode" class="mt10" :label="$t('pages_merchant_add_006')">
                <a-select
                  v-model:value="modelForm.countryCode"
                  class="cp_input"
                  show-search
                  size="large"
                  option-filter-prop="label"
                  :disabled="modelForm.countryCodeDisabled"
                  @change="countryChange"
                >
                  <a-select-option
                    v-for="item in countries"
                    :key="item.areaCode"
                    :value="item.areaCode"
                    :label="language === 'zh' ? item.areaChineseName : item.areaEnglishName"
                  >{{ language === 'zh' ? item.areaChineseName : item.areaEnglishName }}</a-select-option>
                </a-select>
              </a-form-item>
              <div v-if="!modelForm.countryCodeDisabled && refused && modelForm.countryCodeMessage" class="error-area">
                <exclamation-circle-outlined class="ic-error" />
                <span>{{ modelForm.countryCodeMessage }}</span>
              </div>
            </div>
            <div v-if="modelForm.collectionProductType=='B2B'" class="cp_tip">
              <div class="fs16">{{ $t('pages_merchant_add_015') }}</div>
              <div v-if="modelForm.countryCode=='CN'">
                - {{ $t('pages_merchant_add_016') }}<br>
                - {{ $t('pages_merchant_add_017') }}<br>
                - {{ $t('pages_merchant_add_018') }}</div>
              <div v-if="modelForm.countryCode=='HK'">
                - {{ $t('pages_merchant_add_019') }}<br>
                - {{ $t('pages_merchant_add_020') }}<br>
                - {{ $t('pages_merchant_add_021') }}<br>
                - {{ $t('pages_merchant_add_022') }}<br>
                - {{ $t('pages_merchant_add_023') }}<br>
                - {{ $t('pages_merchant_add_018') }}<br>
              </div>
              <div v-if="!['CN','HK'].includes(modelForm.countryCode)">
                <div>- {{ $t('pages_merchant_add_024') }}</div>
                <div class="lineH24 ml12">
                  {{ $t('pages_merchant_add_025') }}<br>
                  {{ $t('pages_merchant_add_026') }}<br>
                  {{ $t('pages_merchant_add_027') }}<br>
                  {{ $t('pages_merchant_add_028') }}
                </div>
                <div>
                  - {{ $t('pages_merchant_add_023') }}<br>
                  - {{ $t('pages_merchant_add_018') }}
                </div>
              </div>
              <div class="fs16 mt40">{{ $t('pages_merchant_add_029') }}</div>
              <div class="df aic">
                <div v-if="modelForm.countryCode=='CN'">- {{ $t('pages_merchant_add_030') }}</div>
                <div v-else>- {{ $t('pages_merchant_add_031') }}</div>
                <a v-if="language === 'en'" class="ml12" @click="downloadTemplate('boardResolution')">{{ $t('pages_merchant_add_037') }}</a>
                <a v-else class="ml12" @click="downloadTemplate('powerAttorney')">{{ $t('pages_merchant_add_037') }}</a>
              </div>
              <div>- {{ $t('pages_merchant_add_032') }}</div>
              <div class="ml12">
                {{ $t('pages_merchant_add_033') }}<br>
                {{ $t('pages_merchant_add_034') }}
              </div>
              <div>- {{ $t('pages_merchant_add_035') }}</div>
              <div>{{ $t('pages_merchant_add_036') }}</div>
            </div>
            <div v-if="modelForm.collectionProductType=='B2C'" class="cp_tip">
              <div class="fs16">{{ $t('pages_merchant_add_015') }}</div>
              <div v-if="modelForm.countryCode=='CN'">
                - {{ $t('pages_merchant_add_016') }}<br>
                - {{ $t('pages_merchant_add_017') }}<br>
                - {{ $t('pages_merchant_add_018') }}
              </div>
              <div v-if="modelForm.countryCode=='HK'">
                - {{ $t('pages_merchant_add_019') }}<br>
                - {{ $t('pages_merchant_add_020') }}<br>
                - {{ $t('pages_merchant_add_021') }}<br>
                - {{ $t('pages_merchant_add_022') }}<br>
                - {{ $t('pages_merchant_add_023') }}<br>
                - {{ $t('pages_merchant_add_018') }}<br>
              </div>
              <div v-if="!['CN','HK'].includes(modelForm.countryCode)">
                <div>- {{ $t('pages_merchant_add_024') }}</div>
                <div class="lineH24 ml12">
                  {{ $t('pages_merchant_add_025') }}<br>
                  {{ $t('pages_merchant_add_026') }}<br>
                  {{ $t('pages_merchant_add_027') }}<br>
                  {{ $t('pages_merchant_add_028') }}
                </div>
                <div>
                  - {{ $t('pages_merchant_add_023') }}<br>
                  - {{ $t('pages_merchant_add_018') }}
                </div>
              </div>
              <div class="fs16 mt40">{{ $t('pages_merchant_add_029') }}</div>
              <div>-
                <span v-if="modelForm.countryCode=='CN'">{{ $t('pages_merchant_add_030') }}</span>
                <span v-else>{{ $t('pages_merchant_add_031') }}</span>
                <!-- <a class="ml12">{{ $t('pages_merchant_add_037') }}</a> -->
                <a v-if="language === 'en'" class="ml12" @click="downloadTemplate('boardResolution')">{{ $t('pages_merchant_add_037') }}</a>
                <a v-else class="ml12" @click="downloadTemplate('powerAttorney')">{{ $t('pages_merchant_add_037') }}</a>
              </div>
              <div>- {{ $t('pages_merchant_add_038') }}</div>
              <div class="ml12">
                {{ $t('pages_merchant_add_039') }}<br>
                {{ $t('pages_merchant_add_040') }}<br>
                {{ $t('pages_merchant_add_041') }}<br>
                {{ $t('pages_merchant_add_042') }}<br>
                {{ $t('pages_merchant_add_043') }}<br>
              </div>
              <div>- {{ $t('pages_merchant_add_035') }}</div>
              <div>{{ $t('pages_merchant_add_036') }}</div>
            </div>
          </div>
          <a-form-item class="taC mt25">
            <a-button
              html-type="submit"
              type="primary"
              shape="round"
              class="minw130"
            >{{ $t('pages_merchant_020') }}</a-button>
          </a-form-item>
        </div>
      </div>
    </a-form>
  </div>
</template>
<script>

import { reactive, toRefs, inject, computed } from 'vue'
import { _user } from '@/api'
export default {
  name: 'CollectionProduct',
  props: {
    model: {
      type: Object,
      default: () => { return {} }
    },
    countries: {
      type: Array,
      default: () => { return [] }
    },
    refused: {
      type: Boolean,
      default: () => { return false }
    }
  },
  setup(props, ctx) {
    const state = reactive({
      themeData: inject('$themeData'),
      language: localStorage.getItem('locale-language') || 'zh'
    })
    state.modelForm = computed(() => {
      return props.model
    })
    const productTypeChange = (e) => {
      const value = e.target.value
      state.modelForm.collectionProductType = value.indexOf('B2B') !== -1 ? 'B2B' : 'B2C'
    }
    const countryChange = (e) => {
      ctx.emit('companyDataEmpty')
    }
    const submit = () => {
      ctx.emit('next', '+')
    }
    const downloadTemplate = async (name) => {
      const res = await _user.exportMerchantTemplate({ name })
      if (res.data.succ) window.open(res.data.data)
    }
    return {
      countryChange,
      productTypeChange,
      submit,
      downloadTemplate,
      ...toRefs(state)
    }
  }
}
</script>
  <style lang="less" scoped>
    .collection-product {
        display: flex;
        justify-content: space-around;
        width:90%;
        margin: auto;
        .apply-step {
            width: 90%;
            margin: 25px auto;
        }
        .cp_radio_box{
            border: 1px solid #DFDFDF;
            border-radius: 10px;
            padding: 20px;
            margin: 30px 20px 20px 0px;
        }
        .cp_tip{
            border: 1px solid #DFDFDF;
            border-radius: 10px;
            padding:20px;
            color: #333;
            font-weight: bold;
            line-height: 33px;
            font-size: 14px;
            max-width: 600px;
        }
        .cp_content_box{
            width: 1150px;
        }
        .cp_input{
          border-radius: 5px !important;
          font-size: 15px !important;
          /deep/.ant-select-selector{
            border-radius: 5px !important;
          }
        }
    }
.error-area {
    padding: 5px 10px 5px 12px;
    border-radius: 5px;
    color: @error-color;
    background: #fef0f0;
    margin-top: -15px;
    text-align: left;
    word-break: break-word;
    .ic-error {
      font-size: 16px;
      margin-right: 12px;
    }
  }
  .df{
    display: flex;
  }
  .aic{
    align-items: center;
  }
  </style>
