<template>
  <a-upload-dragger
    ref="uploadRef"
    v-model:file-list="fileList"
    :class="uploadType === 'square' ? 'upload-auto-default' : 'upload-auto-custom'"
    :list-type="listType"
    :data="ossObj"
    :action="uploadUrl"
    :accept="accept"
    :disabled="disabled"
    :before-upload="beforeUpload"
    :custom-request="customRequest"
    :max-count="maxCount"
    @remove="handleRemove"
    @change="handleChange"
    @preview="handlePreview"
  >
    <div v-if="uploadType === 'square'" class="auto-square">
      <img class="ant-upload-img" src="~@/assets/icons/ic-upload.png">
      <a-button
        v-if="isDown"
        class="down-btn"
        type="primary"
        :loading="loading"
        @click="downTemplate"
      >{{ loading ? $t('common_text_023') : downText }}</a-button>
      <p class="ant-upload-text">
        <span>{{ $t('common_text_027') }}</span>
        <span>{{ $t('common_text_028') }}</span>
        <span>{{ $t('common_text_029') }}</span>
      </p>
      <p class="ant-upload-hint">
        {{ explain }} {{ $t('common_text_009') }}{{ accept }}
        <span v-if="notes" class="ant-upload-notes">{{ notes }}</span>
      </p>
    </div>
    <div v-else class="auto-round">
      <div class="area">
        <div class="area-content">
          <span v-if="explain">{{ explain }}</span>
        </div>
        <div class="area-btn">{{ $t('common_text_004') }}</div>
      </div>
    </div>
  </a-upload-dragger>
</template>
<script>
import { reactive, toRefs, createVNode } from 'vue'
import { _oss } from '@/api/index'
import { notification, Modal } from 'ant-design-vue'
import { generateUUID, fileIsImage } from '@/util'
import i18n from '@/locale/index'
import axios from '@/api/request'
export default {
  name: 'UploadAuto',
  props: {
    accept: {
      type: String,
      default() {
        return ''
      }
    },
    explain: {
      type: String,
      default() {
        return ''
      }
    },
    fileContent: {
      type: Array,
      default() {
        return []
      }
    },
    notes: {
      type: String,
      default() {
        return ''
      }
    },
    downText: {
      type: String,
      default() {
        return i18n.global.t('pages_pay_055')
      }
    },
    maxCount: {
      type: Number,
      default() {
        return 3
      }
    },
    listType: {
      type: String,
      default() {
        return 'text'
      }
    },
    uploadType: {
      type: String,
      default() {
        return 'square'
      }
    },
    isDown: {
      type: Boolean,
      default() {
        return false
      }
    },
    backType: {
      type: String,
      default() {
        return 'string'
      }
    },
    type: {
      type: String,
      default() {
        return 'UPLOAD'
      }
    },
    disabled: {
      type: Boolean,
      default() {
        return false
      }
    },
    nameIndex: {
      type: String,
      default () {
        return ''
      }
    },
    token: {
      type: String,
      default () {
        return ''
      }
    }
  },
  emits: ['update:fileData'],
  setup(props, ctx) {
    const state = reactive({
      loading: false,
      ossObj: {},
      uploadUrl: '',
      fileData: [],
      fileList: []
    })
    const downTemplate = (e) => {
      e.stopPropagation()
      state.loading = true
      ctx.emit('down', () => {
        state.loading = false
      })
    }
    const fileListRefresh = async (file) => {
      const fileData = []
      const fileList = []
      const filePath = Array.isArray(file) ? file : [file]
      const res = await _oss.getOssFileUrl(filePath)
      if (res.data.succ) {
        const { data } = res.data
        for (const i in data) {
          const uuid = generateUUID()
          fileData.push({ uid: uuid, name: i, path: i })
          fileList.push({ uid: uuid, name: i, thumbUrl: data[i], response: data[i], status: 'done' })
        }
        state.fileData = fileData
        state.fileList = fileList
      }
    }
    const beforeUpload = async (file, fileList) => {
      const fileArea = file.name.split('.')
      if (fileArea[0] && fileArea[0].length > 100) {
        notification.error({
          message: i18n.global.t('common_text_015'),
          description: i18n.global.t('common_text_016')
        })
        if (props.maxCount === 1) ctx.emit('update:fileContent', props.backType === 'array' ? [] : '')
        return false
      }
      if (state.fileList.length > props.maxCount - 1 && props.maxCount !== 1) {
        notification.error({
          message: i18n.global.t('common_text_015'),
          description: i18n.global.t('common_text_011') + props.maxCount + i18n.global.t('common_text_012')
        })
        return false
      }
      if (fileArea[1] && props.accept.indexOf(fileArea[fileArea.length - 1].toLowerCase()) === -1) {
        notification.error({
          message: i18n.global.t('common_text_015'),
          description: i18n.global.t('common_text_009') + props.accept
        })
        if (props.maxCount === 1) ctx.emit('update:fileContent', props.backType === 'array' ? [] : '')
        return false
      }
      const data = { type: props.type }
      if (props.token) data.token = props.token
      const res = props.token ? await _oss.getOssUploadTokenNoLogin(data) : await _oss.getOssUploadToken(data)
      if (res.data.succ) {
        const { data } = res.data
        const fileName = file.name.replace(/[%,，]/g, '')
        state.uploadUrl = data.host
        state.ossObj = {
          key: data.path + fileName,
          policy: data.policy,
          signature: data.signature,
          OSSAccessKeyId: res.data.data.accessId,
          success_action_status: '200'
        }
        return true
      }
      return false
    }
    const customRequest = async(options) => {
      const { file, action, data, onSuccess, onError } = options
      data.file = file
      axios.post(action, data, {
        headers: { 'Content-Type': 'multipart/form-data' }
      }).then(res => {
        if (res.status === 200) {
          const params = { filePath: state.ossObj.key }
          return _oss.querySsoTempUrl(params)
        } else {
          throw new Error(i18n.global.t('common_text_017'))
        }
      }).then(res => {
        if (res.data.succ) {
          if (props.maxCount === 1) state.fileData = [{ path: state.ossObj.key, uid: state.fileList[state.fileList.length - 1].uid }]
          else state.fileData.push({ path: state.ossObj.key, uid: state.fileList[state.fileList.length - 1].uid })
          const backFiles = state.fileData.map(item => item.path).join(',')
          if (props.backType === 'array') ctx.emit('update:fileContent', state.fileData.map(item => item.path))
          else ctx.emit('update:fileContent', backFiles)
          state.thumbUrl = res.data.data
          onSuccess(res.data.data)
          ctx.emit('suceefun')
        } else onError()
      }).catch(onError)
    }
    const handlePreview = (file) => {
      const isImg = fileIsImage(file.name)
      if (isImg) {
        Modal.info({
          title: i18n.global.t('pages_receive_047'),
          icon: null,
          width: '35%',
          content: createVNode('img', {
            src: file.response,
            style: { marginTop: '12px', width: '100%' }
          })
        })
      } else {
        window.open(file.response)
      }
    }
    const handleRemove = (file) => {
      state.fileData = state.fileData.filter(item => item.uid !== file.uid)
      const backFiles = state.fileData.map(item => item.path).join(',')
      if (props.backType === 'array') ctx.emit('update:fileContent', state.fileData.map(item => item.path))
      else ctx.emit('update:fileContent', backFiles)
    }
    const handleChange = (info) => {
      if (info.file && info.file.status === 'done') {
        state.fileList = info.fileList.filter(file => file.response)
      } else {
        state.fileList = info.fileList.filter(file => file.status)
      }
      state.fileList = state.fileList.map(item => {
        return Object.assign({}, item, {
          name: item.name.replace(/[%,，]/g, '')
        })
      })
    }
    return {
      downTemplate,
      handleChange,
      beforeUpload,
      customRequest,
      handlePreview,
      handleRemove,
      fileListRefresh,
      ...toRefs(state)
    }
  }
}
</script>

<style lang="less">
.upload-auto-default {
  border-radius: 5px !important;
  border: 2px dashed @main-color !important;
  .ant-upload-drag {
    border-radius: 5px;
    border: 1px dashed @main-color;
    border-width: 3px;
  }
  .auto-square {
    .ant-upload-img {
      width: 50px;
      margin-bottom: 12px;
    }
    .ant-upload-text {
      font-size: 14px !important;
    }
    .ant-upload-text span:nth-child(3) {
      color: @main-color;
    }
    .ant-upload-text span:nth-child(2) {
      margin: 0 5px;
    }
    .ant-upload-notes {
      font-size: 12px;
      color: @error-color;
      margin-left: 5px;
    }
    .ant-upload-hint {
      font-size: 12px !important;
      padding: 0 24px;
      margin-top: 12px;
      text-align: left;
    }
    .down-btn {
      position: absolute;
      right: 24px;
      top: 10px;
    }
  }
}
.upload-auto-custom {
  border: 0 !important;
  background: none !important;
  margin: -12px 0;
  .auto-round {
    .area {
      display: flex;
      border: 1px solid #d9d9d9;
      border-radius: 28px;
      line-height: 32px;
      .area-content {
        width: 75%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #bfbfbf;
        padding: 0 11px;
      }
      .area-btn {
        width: 25%;
        background: @table-th-color;
        border-radius: 28px;
      }
    }
  }
}
.ant-form-item-has-error {
  .upload-auto-default {
    border: 2px dashed @error-color !important;
  }
}
</style>

