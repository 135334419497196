<template>
  <div id="guidePage">
    <div class="header-nav flex-align">
      <img :src="themeData.darkLogoList">
      <div class="nav-right flex-align">
        <global-outlined class="ic-lanage" />
        <language-selector style="padding-left: 10px;" />
      </div>
    </div>
    <div class="body-info">
      <a-steps :current="stepNum" size="small" direction="horizontal" :responsive="false" class="apply-step">
        <a-step :title="$t('pages_merchant_add_001')" />
        <a-step :title="$t('pages_merchant_add_002')" />
        <a-step :title="$t('pages_merchant_add_003')" />
        <a-step :title="$t('pages_merchant_add_004')" />
      </a-steps>
      <div>
        <collection-product
          v-if="stepNum==0"
          :model="collectionInfo"
          :countries="countries"
          :refused="refused"
          @next="handleNext"
          @companyDataEmpty="companyDataEmpty"
        />
        <company-info-page
          v-if="stepNum==1"
          :model="companyInfo"
          :countries="countries"
          :country-code="collectionInfo.countryCode"
          :refused="refused"
          @next="handleNext"
        />
        <person-info-page
          v-if="stepNum==2"
          :model="personInfo"
          :countries="countries"
          :country-code="collectionInfo.countryCode"
          :refused="refused"
          @next="handleNext"
        />
        <business-info-page
          v-if="stepNum==3"
          ref="submitRef"
          :model="businessInfo"
          :countries="countries"
          :country-code="collectionInfo.countryCode"
          :col-info="collectionInfo"
          :refused="refused"
          :page-status="pageStatus"
          @next="handleNext"
          @submit="handSubmit"
        />
      </div>
      <div v-if="isSpin" class="ai_spin">
        <a-spin />
      </div>
    </div>
  </div>
</template>
<script>

import { reactive, toRefs, inject, ref, onUnmounted, watch } from 'vue'
import { _receive, _home, _user } from '@/api'
import LangSelector from '@/components/lang-sel'
import collectionProduct from './components/collection-product'
import companyInfoPage from './components/company-info.vue'
import personInfoPage from './components/person-info.vue'
import businessInfo from './components/business-info.vue'
import { useRouter } from 'vue-router'
import { message } from 'ant-design-vue'
import businessList from '@/common/business'
import i18n from '@/locale'
export default {
  name: 'GuidePage',
  components: {
    'language-selector': LangSelector,
    'collection-product': collectionProduct,
    'company-info-page': companyInfoPage,
    'person-info-page': personInfoPage,
    'business-info-page': businessInfo
  },
  setup() {
    const router = useRouter()
    const submitRef = ref(null)
    const initialCompanyInfo = {
      businessLicensePath: [], // 营业执照 — 中国大陆是必填
      businessLicensePathDisabled: false,
      businessLicensePathMessage: '',
      businessLicenseCertificateNumber: '', // 统一社会信用代码 -中国大陆
      merchantName: '', // 公司注册名称（中文） - 中国大陆
      merchantNameDisabled: false,
      merchantNameMessage: '',
      businessRegistrationNumber: '', // 商业登记证（BR）号码 - 香港
      businessRegistrationNumberDisabled: false,
      businessRegistrationNumberMessage: '',
      companyCertificateCINumber: '', // 公司注册证书（CI）号码 - 香港
      companyCertificateCINumberDisabled: false,
      companyCertificateCINumberMessage: '',
      companyRegistrationCertificateNumber: '', // 公司注册证书证明书号码 - 海外
      companyRegistrationCertificateNumberDisabled: false,
      companyRegistrationCertificateNumberMessage: '',
      merchantNameEn: '', // 公司注册名称（英文）
      merchantNameEnDisabled: false,
      merchantNameEnMessage: '',
      establishDate: '', // 公司成立日期
      establishDateDisabled: false,
      establishDateMessage: '',
      brExpiryDate: '', // 营业期限
      isBrExpiry: false, // 营业期限 - 是否长期
      brExpiryDateDisabled: false,
      brExpiryDateMessage: '',
      registrationAddress: '', // 经营场所
      registrationAddressDisabled: false,
      registrationAddressMessage: '',
      isAddress: true, // 现居住地址与证件住址相同
      mailingAddress: '', // 真实营业地址
      mailingAddressDisabled: false,
      mailingAddressMessage: '',
      proofOfRealBusinessAddress: [], // 真实营业地址证明材料
      proofOfRealBusinessAddressDisabled: false,
      proofOfRealBusinessAddressMessage: '',
      companyAddressType: '', // 地址类型
      companyAddressTypeDisabled: false,
      companyAddressTypeMessage: '',
      businessRegistrationCertificatePath: [], // 商业登记证(BR)
      businessRegistrationCertificatePathDisabled: false,
      businessRegistrationCertificatePathMessage: '',
      incorporationPath: [], // 公司注册证明书(CI文件)
      incorporationPathDisabled: false,
      incorporationPathMessage: '',
      nnc1Path: [], // 法团成立表
      nnc1PathDisabled: false,
      nnc1PathMessage: '',
      nar1Path1: [], // 周年年报
      nar1Path1Disabled: false,
      nar1Path1Message: '',
      mermorandumPath: [], // 公司章程
      mermorandumPathDisabled: false,
      mermorandumPathMessage: '',
      businessRegistrationFilePath: [], // 公司注册文件 - 海外
      businessRegistrationFilePathDisabled: false,
      businessRegistrationFilePathMessage: '',
      isParentCertificate: '', // 您的公司是否存在母公司
      isParentCertificateDisabled: false,
      isParentCertificateMessage: '',
      ownerShipPath: [], // 股权架构图
      ownerShipPathDisabled: false,
      ownerShipPathMessage: '',
      parentCertificatePath: [], // 注册证书及最新的incumbency
      certificatePathDisabled: false,
      certificatePathMessage: '',
      parentCertificate: [{
        country: '', // 母公司注册国家
        name: '' // 母公司注册名称
      }],
      parentCertificateDisabled: false,
      parentCertificateMessage: '',
      isResides: '', // 您公司是否存在分公司/子公司
      isResidesDisabled: false,
      isResidesMessage: '',
      resides: [{
        country: '', // 子公司注册国家
        name: '' // 子公司注册名称
      }],
      residesDisabled: false,
      residesMessage: ''
    }
    const state = reactive({
      isSpin: false,
      timer: null,
      themeData: inject('$themeData'),
      language: localStorage.getItem('locale-language') || 'zh',
      stepNum: 0,
      countries: [],
      refused: false,
      collectionInfo: {
        collectionProductType: 'B2B', // 收款产品类型
        collectionProductTypeDisabled: false,
        collectionProductTypeMessage: '',
        collectionProductSubType: 'B2B2', // 收款产品子类型
        collectionProductSubTypeDisabled: false,
        collectionProductSubTypeMessage: '',
        countryCode: 'HK', // 主体所在注册地
        countryCodeDisabled: false,
        countryCodeMessage: ''
      },
      companyInfo: { ...initialCompanyInfo },
      personInfo: {
        customerIdentity: ['3'], // 请选择您所有的身份
        customerIdentityDisabled: false,
        customerIdentityMessage: '',
        authorizationPath: [], // 董事会决议/授权书
        authorizationPathDisabled: false,
        authorizationPathMessage: '',
        customer: [{ // 授权人
          collDisable: false,
          certificateType: '1', // 证件类型
          issuancePlace: '', // 签发地
          personName: '', // 姓名
          personId: '', // 证件号码
          addressName: '', // 地址
          dateBirth: '', // 出生日期
          certificateDate: '', // 证件到期 日期
          isLongDate: false, // 是否长期
          cert1: [], // 身份证正面
          cert2: [], // 身份证反面
          passport: [] // 护照
        }],
        customerDisabled: false,
        customerMessage: '',
        legalPerson: [], // 法人
        legalPersonDisabled: false,
        legalPersonMessage: '',
        directors: [], // 董事
        directorsDisabled: false,
        directorsMessage: '',
        shareholders: [], // 股东
        shareholdersDisabled: false,
        shareholdersMessage: '',
        isShareholder: null, // 公司是否有持股比例≥25%的个人股东？
        isLegalShareholder: null// 法人代表是否是持股比例≥25%的股东？
      },
      businessInfo: {
        businessBackgroundTradeInGoods: [], // 经营范围-货物贸易
        businessBackgroundTradeInGoodsDisabled: false,
        businessBackgroundTradeInGoodsMessage: '',
        businessBackgroundServiceTrade: [], // 经营范围-服务贸易
        businessBackgroundServiceTradeDisabled: false,
        businessBackgroundServiceTradeMessage: '',
        sourceFunds: ['0', '1'], // 资金来源
        sourceFundsDisabled: false,
        sourceFundsMessage: '',
        paymentPurpose: ['1', '3', '5'], // 预计付款目的
        sourceWhereaboutsDisabled: false,
        sourceWhereaboutsMessage: '',
        currentClientCountryCode: [], // 海外买家和资金来自于哪些国家和地区？
        currentClientCountryCodeDisabled: false,
        currentClientCountryCodeMessage: '',
        currentSuppliersCountryCode: [], // 您的供应商和资金付款主要是哪些国家地区
        currentSuppliersCountryCodeDisabled: false,
        currentSuppliersCountryCodeMessage: '',
        permonthTransactionAmount_v_two: '0', // 预估交易量 (USD/月)
        permonthTransactionAmount_v_twoDisabled: false,
        permonthTransactionAmount_v_twoMessage: '',
        businessWebsite: '', // 经营网址
        businessWebsiteDisabled: false,
        businessWebsiteMessage: '',
        businessWebsitePath: [], // 经营网址持有证明
        businessWebsitePathDisabled: false,
        businessWebsitePathMessage: '',
        hisTransFilePath: [], // 历史交易还原材料或业务说明
        hisTransFilePathDisabled: false,
        hisTransFilePathMessage: '',
        isETradePlatform: '0', // 是否经营手机支付, 手机聊天APPS, 代收代付第三方, 商户支付平台, 游戏币换钱, 网站允许充值电子钱包充值卡平台?
        isETradePlatformDisabled: false,
        isETradePlatformMessage: '',
        questionnairesPath: [], // 否经营手机支付,为是时上传文件
        questionnairesPathDisabled: false,
        questionnairesPathMessage: '',
        accessories: [], // 其他附件
        accessoriesDisabled: false,
        accessoriesMessage: '',
        approvePurpose: '', // 附言
        approvePurposeDisabled: false,
        approvePurposeMessage: '',
        isRiskCountryTransaction: '0', // 您公司或您的任何客户、交易对手和合作伙伴在过去或现在是否与全球风险国家和地区有任何直接或间接的业务活动？
        riskTransaction: [{
          country: '', // 风险交易国家
          name: '' // 风险国家公司名称
        }],
        riskTransactionDisabled: false,
        riskTransactionMessage: '',
        checkOne: null,
        checkTwo: null
      },
      submitInfo: {},
      vaInfo: {},
      pageStatus: '',
      pageType: 'add'
    })
    const loadAreaPub = async () => {
      const res = await _receive.getAreaPub()
      if (res.data.succ) {
        state.countries = res.data.data
      }
    }
    const initPage = async () => {
      state.isSpin = true
      const res = await _home.getMerchantInfo()
      if (res.data.succ) {
        const { status, merchantNo } = res.data.data
        state.pageStatus = status
        state.merchantNo = merchantNo
        if (status === 'CUSTREJ') loadMerchantInfo()
        else loadTemporaryValue()
      } else {
        state.isSpin = false
      }
      loadAreaPub()
      state.timer = setInterval(() => {
        setTemporaryValue()
      }, 30000)
    }
    const loadMerchantInfo = async () => {
      state.isSpin = true
      state.pageType = 'edit'
      const res = await _user.getRejectCustomerKyc()
      if (res.data.succ) {
        const { data } = res.data
        state.refused = true
        if (data.levelFlag === '1') state.risked = true
        dealMerchantInfo(data.merchantInfo, data.rejectFileName, data.vaInfo)
      } else {
        state.isSpin = false
      }
    }
    const dealMerchantInfo = (merInfo, rejInfo, vaInfo) => {
      state.vaInfo = vaInfo
      for (const k in state.companyInfo) { // 公司信息
        if (!k.endsWith('Disabled') && !k.endsWith('Message')) {
          state.companyInfo[k] = merInfo[k]
          state.companyInfo[k + 'Disabled'] = !Object.keys(rejInfo).includes(k)
          state.companyInfo[k + 'Message'] = Object.keys(rejInfo).includes(k) ? rejInfo[k] : ''
        }
        if (['parentCertificate', 'resides'].includes(k)) {
          state.companyInfo[k] = state.companyInfo[k] ? state.companyInfo[k] : [{ country: 'CN', name: 'xx' }]
        }
      }
      state.companyInfo.isBrExpiry = state.companyInfo.brExpiryDate === '2099-12-31'
      state.companyInfo.isAddress = state.companyInfo.registrationAddress === state.companyInfo.mailingAddress
      for (const k in state.personInfo) { // 身份认证
        if (!k.endsWith('Disabled') && !k.endsWith('Message')) {
          state.personInfo[k] = merInfo[k]
          state.personInfo[k + 'Disabled'] = !Object.keys(rejInfo).includes(k)
          state.personInfo[k + 'Message'] = Object.keys(rejInfo).includes(k) ? rejInfo[k] : ''
          if (['customer', 'legalPerson'].includes(k)) { // 授权人/法人
            state.personInfo[k] = showArray(merInfo[k] ? merInfo[k] : [], k)
            if (k === 'customer' && merInfo.customerIdentity.includes('3') && (!merInfo[k] || merInfo[k].length === 0)) {
              state.personInfo[k] = [{ // 授权人
                collDisable: false,
                certificateType: '1', // 证件类型
                issuancePlace: '', // 签发地
                personName: '', // 姓名
                personId: '', // 证件号码
                addressName: '', // 地址
                dateBirth: '', // 出生日期
                certificateDate: '', // 证件到期 日期
                isLongDate: false, // 是否长期
                cert1: [], // 身份证正面
                cert2: [], // 身份证反面
                passport: [] // 护照
              }]
            }
          }
          if (['directors'].includes(k)) { // 董事
            state.personInfo[k] = showArray(merInfo.director ? merInfo.director : [], k)
            state.personInfo[k + 'Message'] = Object.keys(rejInfo).includes('director') ? rejInfo['director'] : ''
            state.personInfo[k + 'Disabled'] = !Object.keys(rejInfo).includes('director')
          }
          if (['shareholders'].includes(k)) { // 股东
            state.personInfo[k] = showArray(merInfo.shareholder ? merInfo.shareholder : [], k)
            state.personInfo[k + 'Message'] = Object.keys(rejInfo).includes('shareholder') ? rejInfo['shareholder'] : ''
            state.personInfo[k + 'Disabled'] = !Object.keys(rejInfo).includes('shareholder')
          }
        }
      }
      state.personInfo.isShareholder = merInfo.shareholder && merInfo.shareholder.length > 0 ? '1' : '0'
      let isLegeal = false
      const personJson = merInfo.countryCode === 'CN' ? (merInfo.legalPerson ? merInfo.legalPerson : []) : (merInfo.director ? merInfo.director : []) // 中国时 法人 否则董事
      if (merInfo.shareholder) {
        merInfo.shareholder.forEach(sit => {
          if (personJson.some(i => i.idNumber === sit.idNumber)) {
            isLegeal = true
          }
        })
      }
      state.personInfo.isLegalShareholder = isLegeal ? '1' : '0'
      for (const k in state.businessInfo) { // 业务信息
        if (!k.endsWith('Disabled') && !k.endsWith('Message')) {
          state.businessInfo[k] = merInfo[k]
          if (['riskTransaction'].includes(k)) {
            state.businessInfo[k] = state.businessInfo[k] ? state.businessInfo[k] : [{ country: '', name: '' }]
          }
          if (['currentClientCountryCode'].includes(k)) {
            state.businessInfo[k] = merInfo.currentClientCountry ? merInfo.currentClientCountry : []
          }
          if (['currentSuppliersCountryCode'].includes(k)) {
            state.businessInfo[k] = merInfo.currentSuppliersCountry ? merInfo.currentSuppliersCountry : []
          }
          if (['permonthTransactionAmount_v_two'].includes(k)) {
            state.businessInfo[k] = merInfo.perMonthTransactionAmountVTwo ? merInfo.perMonthTransactionAmountVTwo : ''
          }
          if (k === 'currentClientCountryCode') {
            state.businessInfo[k + 'Disabled'] = !Object.keys(rejInfo).includes('currentClientCountry')
            state.businessInfo[k + 'Message'] = Object.keys(rejInfo).includes('currentClientCountry') ? rejInfo['currentClientCountry'] : ''
          } else if (k === 'currentSuppliersCountryCode') {
            state.businessInfo[k + 'Disabled'] = !Object.keys(rejInfo).includes('currentSuppliersCountry')
            state.businessInfo[k + 'Message'] = Object.keys(rejInfo).includes('currentSuppliersCountry') ? rejInfo['currentSuppliersCountry'] : ''
          } else if (k === 'permonthTransactionAmount_v_two') {
            state.businessInfo[k + 'Disabled'] = !Object.keys(rejInfo).includes('perMonthTransactionAmountVTwo')
            state.businessInfo[k + 'Message'] = Object.keys(rejInfo).includes('perMonthTransactionAmountVTwo') ? rejInfo['perMonthTransactionAmountVTwo'] : ''
          } else {
            state.businessInfo[k + 'Disabled'] = !Object.keys(rejInfo).includes(k)
            state.businessInfo[k + 'Message'] = Object.keys(rejInfo).includes(k) ? rejInfo[k] : ''
          }
        }
      }
      for (const k in state.collectionInfo) { // 收款产品
        if (!k.endsWith('Disabled') && !k.endsWith('Message')) {
          state.collectionInfo[k] = Array.isArray(merInfo[k]) ? merInfo[k][0] : merInfo[k]
          state.collectionInfo[k + 'Disabled'] = !Object.keys(rejInfo).includes(k)
          state.collectionInfo[k + 'Message'] = Object.keys(rejInfo).includes(k) ? rejInfo[k] : ''
        }
      }
      state.isSpin = false
    }
    const showArray = (arr, name) => { // 授权人/法人/董事/股东的证件信息处理
      const data = []
      arr.forEach(k => {
        const defJson = {
          collDisable: false,
          certificateType: k.certType, // 证件类型
          issuancePlace: k.issuancePlace, // 签发地
          personName: k.certName, // 姓名
          personId: k.idNumber, // 证件号码
          addressName: k.certAddressName, // 地址
          dateBirth: k.dateOfBirth, // 出生日期
          certificateDate: k.certExpiryDate, // 证件到期 日期
          isLongDate: k.certExpiryDate === '2099-12-31', // 是否长期
          cert1: k.certType === '0' ? (k.path[0] ? [k.path[0]] : []) : [], // 身份证正面
          cert2: k.certType === '0' ? (k.path[1] ? [k.path[1]] : []) : [], // 身份证反面
          passport: k.certType === '1' ? k.path : [] // 护照
        }
        let selJson = {}
        if (name !== 'customer') {
          selJson = {
            phone: k.phoneNo, // 电话号码
            isAddress: true, // 现居住地址与身份证件住址相同
            addressCountryCode: k.country, // 现居住地址-国家
            addressName2: k.liveAddressName// 现居住地址-地址
          }
        }
        data.push({ ...defJson, ...selJson })
      })
      return data
    }
    const loadTemporaryValue = async () => {
      state.isSpin = true
      const res = await _user.getTemporaryValue({ key: state.merchantNo })
      if (res.data.succ) {
        const { data } = res.data
        if (data) {
          const info = JSON.parse(data)
          if ([0, 1, 2, 3].includes(info.stepNum)) {
            state.stepNum = info.stepNum
            state.collectionInfo = info.collectionInfo
            state.companyInfo = info.companyInfo
            state.personInfo = info.personInfo
            state.businessInfo = info.businessInfo
            state.submitInfo = info.submitInfo
          }
        }
      }
      state.isSpin = false
    }
    const handleNext = (type) => {
      if (type === '-') {
        state.stepNum = state.stepNum - 1
      } else {
        state.stepNum = state.stepNum + 1
      }
    }
    const setTemporaryValue = () => {
      const data = {
        stepNum: state.stepNum,
        merchantNo: state.merchantNo,
        collectionInfo: state.collectionInfo,
        companyInfo: state.companyInfo,
        personInfo: state.personInfo,
        businessInfo: state.businessInfo,
        submitInfo: state.submitInfo,
        vaInfo: state.vaInfo ? state.vaInfo : null
      }
      _user.setTemporaryValue({
        key: state.merchantNo,
        value: JSON.stringify(data)
      })
    }
    const handSubmit = (e) => {
      submitFun(e)
    }
    const handlePerson = (old, titleList) => { // 授权人/法人/董事/股东的证件信息处理
      const { certificateType, issuancePlace, personName, personId, addressName, dateBirth, certificateDate, cert1, cert2, passport, addressCountryCode, addressName2, phone } = old
      const data = {}
      titleList.forEach(k => {
        if (['customerIssuancePlace', 'legalPersonCertIssuancePlace', 'directorCertIssuancePlace', 'shareholderCertIssuancePlace', 'customerCitizenship', 'legalPersonCitizenship', 'directorCitizenship', 'shareholderCitizenship'].includes(k)) {
          data[k] = issuancePlace // 签发地
        }
        if (['customerCertType', 'legalPersonCertType', 'directorCertType', 'shareholderCertType'].includes(k)) {
          data[k] = certificateType // 证件类型
        }
        if (['customerCertPath', 'legalPersonCertPath', 'directorCertPath', 'shareholderCertPath'].includes(k)) {
          data[k] = certificateType === '1' ? passport : [...cert1, ...cert2] // 证件
        }
        if (['customerCertName', 'legalPersonCertName', 'directorCertName', 'shareholderCertName'].includes(k)) {
          data[k] = personName // 名称
        }
        if (['customerIDNumber', 'legalPersonIDNumber', 'directorIDNumber', 'shareholderIDNumber'].includes(k)) {
          data[k] = personId // 证件号码
        }
        if (['customerDateOfBirth', 'legalPersonDateOfBirth', 'directorDateOfBirth', 'shareholderDateOfBirth'].includes(k)) {
          data[k] = dateBirth // 出生日期
        }
        if (['customerCertExpiryDate', 'legalPersonCertExpiryDate', 'directorCertExpiryDate', 'shareholderCertExpiryDate'].includes(k)) {
          data[k] = certificateDate // 证件到期日期
        }
        if (['customerCertAddressName', 'legalPersonCertAddressName', 'directorCertAddressName', 'shareholderCertAddressName'].includes(k)) {
          data[k] = addressName // 身份证地址
        }
        if (['legalPersonCountry', 'directorCountry', 'shareholderCountry'].includes(k)) {
          data[k] = addressCountryCode || issuancePlace // 现居住国家
        }
        if (['legalPersonLiveAddressName', 'directorLiveAddressName', 'shareholderLiveAddressName'].includes(k)) {
          data[k] = addressName2 || addressName// 现居住地址
        }
        if (['legalPersonPhoneNo', 'directorPhoneNo', 'shareholderPhoneNo'].includes(k)) {
          data[k] = phone // 电话号码
        }
      })
      return data
    }
    const submitFun = async (tip) => {
      const data = JSON.parse(JSON.stringify({ ...state.collectionInfo, ...state.companyInfo, ...state.businessInfo, ...state.personInfo, ...state.businessInfo }))
      if (data.isParentCertificate === '1') { // 母公司
        data.parentCertificate = data.parentCertificate.filter(item => item.country && item.country.trim() !== '').map(item => {
          return {
            parentCertificateName: item.name,
            parentCertificateCountry: item.country
          }
        })
      } else {
        data.parentCertificate = []
      }
      if (data.isResides === '1') { // 子公司
        data.resides = data.resides.filter(item => item.country && item.country.trim() !== '').map(item => {
          return {
            residesName: item.name,
            residesCountry: item.country
          }
        })
      } else {
        data.resides = []
      }

      if (data.isRiskCountryTransaction === '1') { // 风险国家
        data.riskTransaction = data.riskTransaction.filter(item => item.country && item.country.trim() !== '').map(item => {
          return {
            riskTransactionCompanyName: item.name,
            riskTransactionCountry: item.country
          }
        })
      } else {
        data.riskTransaction = []
      }

      if (data.customer && data.customer.length > 0) {
        const authTitle = ['customerIssuancePlace', 'customerCitizenship', 'customerCertType', 'customerCertPath', 'customerCertName', 'customerIDNumber', 'customerDateOfBirth', 'customerCertExpiryDate', 'customerCertAddressName', 'customerLiveAddressName']
        // const authData = data.customer.length > 0 ? handlePerson(data.customer[0], authTitle) : {} // 授权人
        // if (data.customer.length > 0) {
        //   authData.customerCitizenship = authData.customerIssuancePlace
        //   authData.customerLiveAddressName = authData.customerCertAddressName
        // }
        data.customers = data.customer.map(k => { // 授权人
          const item = handlePerson(k, authTitle)
          item.customerLiveAddressName = item.customerCertAddressName
          return item
        })
      } else {
        data.customers = []
      }
      delete data.customer
      if (data.legalPerson && data.legalPerson.length > 0 && state.collectionInfo.countryCode === 'CN') {
        const legalList = ['legalPersonCertIssuancePlace', 'legalPersonCitizenship', 'legalPersonCertType', 'legalPersonCertPath', 'legalPersonCertName', 'legalPersonIDNumber', 'legalPersonDateOfBirth', 'legalPersonCertExpiryDate', 'legalPersonCertAddressName', 'legalPersonCountry', 'legalPersonLiveAddressName', 'legalPersonPhoneNo']
        // const legalData = data.legalPerson.length > 0 ? handlePerson(data.legalPerson[0], legalList) : {} // 法人
        data.legalPersons = data.legalPerson.map(k => { // 法人
          const item = handlePerson(k, legalList)
          return item
        })
      } else {
        data.legalPersons = []
      }
      delete data.legalPerson
      if (data.directors && data.directors.length > 0 && state.collectionInfo.countryCode !== 'CN') {
        const directorList = ['directorCertIssuancePlace', 'directorCitizenship', 'directorCertType', 'directorCertPath', 'directorCertName', 'directorIDNumber', 'directorDateOfBirth', 'directorCertExpiryDate', 'directorCertAddressName', 'directorCountry', 'directorLiveAddressName', 'directorPhoneNo']
        data.directors = data.directors.map(k => { // 董事
          const item = handlePerson(k, directorList)
          return item
        })
      } else {
        data.directors = []
      }
      if (data.shareholders && data.shareholders.length > 0) {
        const shareholderList = ['shareholderCertIssuancePlace', 'shareholderCitizenship', 'shareholderCertType', 'shareholderCertPath', 'shareholderCertName', 'shareholderIDNumber', 'shareholderDateOfBirth', 'shareholderCertExpiryDate', 'shareholderCertAddressName', 'shareholderCountry', 'shareholderLiveAddressName', 'shareholderPhoneNo']
        data.shareholders = data.shareholders.map(k => { // 股东
          const item = handlePerson(k, shareholderList)
          return item
        })
      } else {
        data.shareholders = []
      }
      clearInterval(state.timer)
      for (const i in data) {
        if (i.endsWith('Disabled') || i.endsWith('Message')) {
          delete data[i]
        }
      }
      if (tip === 'va' && state.pageStatus !== 'CUSTREJ') {
        state.submitInfo = data
        clearInterval(state.timer)
        // setTemporaryValue()
        // approveVA()
        approveFun(data, 'va')
      } else {
        if (state.pageStatus === 'CUSTREJ') {
          approveFun(Object.assign(data, { vaInfo: state.vaInfo }))
        } else {
          approveFun(data)
        }
      }
    }
    const approveVA = () => {
      const query = { p: 'va', c: state.collectionInfo.collectionProductSubType, n: 'o' }
      localStorage.setItem('merchantAdd', JSON.stringify({
        nameEn: state.companyInfo.merchantNameEn,
        businessWebsite: state.businessInfo.businessWebsite,
        hisTransFilePath: state.businessInfo.hisTransFilePath,
        businessWebsitePath: state.businessInfo.businessWebsitePath
      }))
      if (state.collectionInfo.collectionProductType === 'B2C' && state.vaInfo && Object.keys(state.vaInfo).includes('businessChildType') && Object.keys(state.vaInfo).includes('businessType')) {
        const params = {
          businessType: state.vaInfo.businessType,
          businessChildType: state.vaInfo.businessChildType,
          p: 'va',
          c: state.collectionInfo.collectionProductSubType
        }
        const business = businessList.find(item => item.value === state.vaInfo.businessChildType)
        if (business) {
          params.pageName = business.pageName
          router.push({ path: '/receive/e_commerce', query: params })
        } else {
          router.push({ path: '/receive/apply_type', query })
        }
      } else {
        router.push({ path: '/receive/apply_type', query })
      }
    }
    const approveFun = async (data, type) => {
      submitRef.value.loading = true
      const res = await _user.approveMaterial(data)
      submitRef.value.loading = false
      if (res.data.succ) {
        if (type === 'va') {
          approveVA()
        } else {
          message.success(i18n.global.t('pages_merchant_105'))
          if (res.data.data) {
            console.log('跳转至活体提示')
            router.replace({ path: 'guide_liveness', query: { isBack: true }})
          } else {
            console.log('跳转至常规提示')
            router.replace({ path: 'guide_success', query: { isBack: true }})
          }
        }
      } else if (res.data.resCode === '010039') {
        message.error(i18n.global.t('pages_merchant_106'))
      } else {
        message.error(res.data.resMsg)
      }
    }
    const companyDataEmpty = () => {
      state.companyInfo = { ...initialCompanyInfo } // 重新赋值为初始值
    }
    onUnmounted(() => { // 组件卸载
      clearInterval(state.timer)
    })
    watch(
      () => state.collectionInfo.countryCode,
      (newValue) => {
        const perInfo = [{
          collDisable: false,
          certificateType: '1', // 证件类型
          issuancePlace: '', // 签发地
          personName: '', // 姓名
          personId: '', // 证件号码
          addressName: '', // 授权人地址
          dateBirth: '', // 出生日期
          certificateDate: '', // 证件到期 日期
          isLongDate: false, // 是否长期
          cert1: [], // 身份证正面
          cert2: [], // 身份证反面
          passport: [], // 护照
          phone: '', // 电话号码
          isAddress: true, // 现居住地址与身份证件住址相同
          addressCountryCode: '', // 现居住地址-国家
          addressName2: ''// 现居住地址-地址
        }]
        if (newValue === 'CN') {
          state.personInfo.directors = []
          if (!state.personInfo.legalPerson || state.personInfo.legalPerson.length === 0) {
            state.personInfo.legalPerson = perInfo // 法人
          }
        } else {
          state.personInfo.legalPerson = []
          if (!state.personInfo.directors || state.personInfo.directors.length === 0) {
            state.personInfo.directors = perInfo // 董事
          }
        }
      }, { immediate: true }
    )

    initPage()
    return {
      loadAreaPub,
      loadMerchantInfo,
      loadTemporaryValue,
      handleNext,
      setTemporaryValue,
      approveVA,
      submitFun,
      handSubmit,
      submitRef,
      handlePerson,
      approveFun,
      dealMerchantInfo,
      showArray,
      companyDataEmpty,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
#guidePage {
    text-align: left;
    color: #000;

    .header-nav {
        height: 60px;
        padding: 0 24px;
        margin-bottom: 4px;
        box-shadow: 0 0 6px 0 #d9d9d9;

        img {
            width: 150px;
        }

        .nav-right {
            margin-left: auto;
            cursor: pointer;

            .ic-lanage {
                color: @main-color;
                font-size: 24px;
            }

            .text {
                margin-left: 8px;
                font-size: 16px;
                font-weight: 600;
            }
        }
    }

    .body-info {
        .apply-step {
            width: 90%;
            margin: 25px auto;
        }
        .ai_spin{
          text-align: center;
          line-height: 100vh;
          position: fixed;
          left: 0;
          right: 0;
          top:0%;
          bottom: 0%;
          background-color: rgba(255,255,255,0.4);
          z-index: 99;
        }
    }
}
.error-area {
    width: 250px;
    height: 300px;
    margin-left: 30px;
    padding: 12px 24px;
    border-radius: 7px;
    color: @error-color;
    background: #fef0f0;
    overflow-y: scroll;
    word-break: break-word;
    .title {
      font-weight: 500;
      .ic-error {
        font-size: 20px;
        margin-right: 12px;
      }
    }
    .comments {
      white-space: pre-wrap;
      margin-top: 12px;
    }
  }
</style>
