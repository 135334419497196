<template>
  <div id="guidePage">
    <a-form
      ref="step1Ref"
      class="ant-round-form material-company-register-form"
      layout="vertical"
      :model="modelForm"
      :hide-required-mark="true"
      @finish="submit"
    >
      <div class="business-info">
        <div class="cp_content_box">
          <div class="fwb fs20">{{ $t('pages_merchant_add_083') }}</div>
          <div class="fs14 mt5">{{ $t('pages_merchant_add_084') }}</div>
          <a-form-item v-if="colInfo.collectionProductSubType=='B2B2'" class="mt15" name="businessBackgroundServiceTrade" :label="$t('pages_merchant_add_085')" :rules="rulesChange">
            <a-select
              v-model:value="modelForm.businessBackgroundServiceTrade"
              class="bi_input"
              show-search
              size="large"
              mode="multiple"
              option-filter-prop="label"
              :placeholder="$t('pages_merchant_add_055')"
              :disabled="modelForm.businessBackgroundServiceTradeDisabled && colInfo.collectionProductSubTypeDisabled && colInfo.collectionProductTypeDisabled"
            >
              <a-select-option
                v-for="scope in scopeServiceList"
                :key="scope.indexKey"
                :value="scope.title"
                :label="scope.title"
              >{{ scope.titleNot }}</a-select-option>
            </a-select>
            <div v-if="!modelForm.businessBackgroundServiceTradeDisabled && refused && modelForm.businessBackgroundServiceTradeMessage" class="error-area">
              <exclamation-circle-outlined class="ic-error" />
              <span>{{ modelForm.businessBackgroundServiceTradeMessage }}</span>
            </div>
          </a-form-item>
          <a-form-item v-if="colInfo.collectionProductSubType!='B2B2'" class="mt15" name="businessBackgroundTradeInGoods" :label="$t('pages_merchant_add_085')" :rules="rulesChange">
            <a-select
              v-model:value="modelForm.businessBackgroundTradeInGoods"
              class="bi_input"
              show-search
              size="large"
              mode="multiple"
              option-filter-prop="label"
              :placeholder="$t('pages_merchant_add_055')"
              :disabled="modelForm.businessBackgroundTradeInGoodsDisabled && colInfo.collectionProductSubTypeDisabled && colInfo.collectionProductTypeDisabled"
            >
              <a-select-option
                v-for="scope in scopeTradeList"
                :key="scope.indexKey"
                :value="scope.title"
                :label="scope.title"
              >{{ scope.titleNot }}</a-select-option>
            </a-select>
            <div v-if="!modelForm.businessBackgroundTradeInGoodsDisabled && refused && modelForm.businessBackgroundTradeInGoodsMessage" class="error-area">
              <exclamation-circle-outlined class="ic-error" />
              <span>{{ modelForm.businessBackgroundTradeInGoodsMessage }}</span>
            </div>
          </a-form-item>
          <a-form-item name="sourceFunds" :label="$t('pages_merchant_add_086')" :rules="rulesChange">
            <a-select
              v-model:value="modelForm.sourceFunds"
              class="bi_input"
              show-search
              mode="multiple"
              size="large"
              option-filter-prop="label"
              :placeholder="$t('pages_merchant_add_055')"
              :disabled="modelForm.sourceFundsDisabled"
            >
              <a-select-option
                v-for="source in sourceList"
                :key="source.value"
                :value="source.value"
              >{{ $t(source.key) }}</a-select-option>
            </a-select>
            <div v-if="!modelForm.sourceFundsDisabled && refused && modelForm.sourceFundsMessage" class="error-area">
              <exclamation-circle-outlined class="ic-error" />
              <span>{{ modelForm.sourceFundsMessage }}</span>
            </div>
          </a-form-item>
          <a-form-item name="paymentPurpose" :label="$t('pages_merchant_add_087')" :rules="rulesChange">
            <a-select
              v-model:value="modelForm.paymentPurpose"
              class="bi_input"
              show-search
              mode="multiple"
              size="large"
              option-filter-prop="label"
              :placeholder="$t('pages_merchant_add_055')"
              :disabled="modelForm.paymentPurposeDisabled"
            >
              <a-select-option
                v-for="source in sourceWheresList"
                :key="source.value"
                :value="source.value"
              >{{ $t(source.key) }}</a-select-option>
            </a-select>
            <div v-if="!modelForm.paymentPurposeDisabled && refused && modelForm.paymentPurposeMessage" class="error-area">
              <exclamation-circle-outlined class="ic-error" />
              <span>{{ modelForm.paymentPurposeMessage }}</span>
            </div>
          </a-form-item>
          <a-form-item name="currentClientCountryCode" :label="$t('pages_merchant_add_088')" :rules="rulesChange">
            <a-select
              v-model:value="modelForm.currentClientCountryCode"
              class="bi_input"
              show-search
              mode="multiple"
              size="large"
              option-filter-prop="label"
              :placeholder="$t('pages_merchant_add_055')"
              :disabled="modelForm.currentClientCountryCodeDisabled"
            >
              <a-select-option
                v-for="item in countries"
                :key="item.areaCode"
                :value="item.areaCode"
                :label="language === 'zh' ? item.areaChineseName : item.areaEnglishName"
              >{{ language === 'zh' ? item.areaChineseName : item.areaEnglishName }}</a-select-option>
            </a-select>
            <div v-if="!modelForm.currentClientCountryCodeDisabled && refused && modelForm.currentClientCountryCodeMessage" class="error-area">
              <exclamation-circle-outlined class="ic-error" />
              <span>{{ modelForm.currentClientCountryCodeMessage }}</span>
            </div>
          </a-form-item>
          <a-form-item name="currentSuppliersCountryCode" :label="$t('pages_merchant_add_089')" :rules="rulesChange">
            <a-select
              v-model:value="modelForm.currentSuppliersCountryCode"
              class="bi_input"
              show-search
              mode="multiple"
              size="large"
              option-filter-prop="label"
              :placeholder="$t('pages_merchant_add_055')"
              :disabled="modelForm.currentSuppliersCountryCodeDisabled"
            >
              <a-select-option
                v-for="item in countries"
                :key="item.areaCode"
                :value="item.areaCode"
                :label="language === 'zh' ? item.areaChineseName : item.areaEnglishName"
              >{{ language === 'zh' ? item.areaChineseName : item.areaEnglishName }}</a-select-option>
            </a-select>
            <div v-if="!modelForm.currentSuppliersCountryCodeDisabled && refused && modelForm.currentSuppliersCountryCodeMessage" class="error-area">
              <exclamation-circle-outlined class="ic-error" />
              <span>{{ modelForm.currentSuppliersCountryCodeMessage }}</span>
            </div>
          </a-form-item>
          <a-form-item name="permonthTransactionAmount_v_two" :label="$t('pages_merchant_add_090')" :rules="rulesChange">
            <a-select
              v-model:value="modelForm.permonthTransactionAmount_v_two"
              class="bi_input"
              show-search
              size="large"
              option-filter-prop="label"
              :placeholder="$t('pages_merchant_add_055')"
              :disabled="modelForm.permonthTransactionAmount_v_twoDisabled"
            >
              <a-select-option
                v-for="t in tradeAmountList"
                :key="t.value"
                :value="t.value"
                :label="t.key"
              >{{ t.key }}</a-select-option>
            </a-select>
            <div v-if="!modelForm.permonthTransactionAmount_v_twoDisabled && refused &&modelForm.permonthTransactionAmount_v_twoMessage" class="error-area">
              <exclamation-circle-outlined class="ic-error" />
              <span>{{ modelForm.permonthTransactionAmount_v_twoMessage }}</span>
            </div>
          </a-form-item>
          <a-form-item name="businessWebsite" :label="$t('pages_merchant_add_091')" :rules="colInfo.collectionProductType=='B2C'?rulesChange:{}">
            <a-input
              v-model:value="modelForm.businessWebsite"
              class="bi_input"
              size="large"
              allow-clear
              :disabled="modelForm.businessWebsiteDisabled"
              :placeholder="$t('pages_merchant_add_044')"
            />
            <div v-if="!modelForm.businessWebsiteDisabled && refused && modelForm.businessWebsiteMessage" class="error-area">
              <exclamation-circle-outlined class="ic-error" />
              <span>{{ modelForm.businessWebsiteMessage }}</span>
            </div>
          </a-form-item>
          <a-form-item v-if="colInfo.collectionProductType=='B2C'" name="businessWebsitePath" :label="$t('pages_merchant_add_092')" :rules="rulesChange" class="bi_upload mt15">
            <upload-auto
              ref="uploadWRef"
              v-model:file-content="modelForm.businessWebsitePath"
              accept=".jpg,.jpeg,.png,.pdf,.bmp"
              type="INVITE"
              list-type="picture"
              back-type="array"
              :disabled="modelForm.businessWebsitePathDisabled"
              :explain="$t('pages_merchant_032')"
              :max-count="1"
            />
            <div v-if="!modelForm.businessWebsitePathDisabled && refused && modelForm.businessWebsitePathMessage" class="error-area">
              <exclamation-circle-outlined class="ic-error" />
              <span>{{ modelForm.businessWebsitePathMessage }}</span>
            </div>
          </a-form-item>
          <a-form-item v-if="colInfo.collectionProductType=='B2B'" name="hisTransFilePath" :label="$t('pages_merchant_add_093')" :rules="rulesChange" class="bi_upload mt15">
            <upload-auto
              ref="uploadHRef"
              v-model:file-content="modelForm.hisTransFilePath"
              class="mt5"
              accept=".jpg,.jpeg,.png,.pdf,.bmp"
              type="INVITE"
              list-type="picture"
              back-type="array"
              :disabled="modelForm.hisTransFilePathDisabled"
              :explain="$t('pages_merchant_032')"
              :max-count="2"
            />
            <div v-if="!modelForm.hisTransFilePathDisabled && refused && modelForm.hisTransFilePathMessage" class="error-area">
              <exclamation-circle-outlined class="ic-error" />
              <span>{{ modelForm.hisTransFilePathMessage }}</span>
            </div>
          </a-form-item>
          <a-form-item class="df aic mt15" name="isETradePlatform" :rules="rulesChange">
            <div>{{ $t('pages_merchant_add_094') }}</div>
            <a-radio-group v-model:value="modelForm.isETradePlatform" :disabled="modelForm.isETradePlatformDisabled" class="mt5">
              <a-radio value="1">{{ $t('pages_merchant_add_078') }}</a-radio>
              <a-radio value="0" class="ml12">{{ $t('pages_merchant_add_079') }}</a-radio>
            </a-radio-group>
            <div v-if="!modelForm.isETradePlatformDisabled && refused && modelForm.isETradePlatformMessage" class="error-area">
              <exclamation-circle-outlined class="ic-error" />
              <span>{{ modelForm.isETradePlatformMessage }}</span>
            </div>
          </a-form-item>
          <a-form-item v-if="modelForm.isETradePlatform=='1'" class="df aic mt10 bi_upload" name="questionnairesPath" :rules="rulesUpload">
            <div class="df jusb">
              <div />
              <a-button type="primary" size="small" @click="downloadTemplate('questionnaires')">{{ $t('pages_pay_055') }}</a-button>
            </div>
            <upload-auto
              ref="uploadETPRef"
              v-model:file-content="modelForm.questionnairesPath"
              accept=".jpg,.jpeg,.png,.pdf,.bmp"
              type="INVITE"
              list-type="picture"
              back-type="array"
              :disabled="modelForm.questionnairesPathDisabled"
              :explain="$t('pages_merchant_032')"
              :max-count="2"
            />
            <div v-if="!modelForm.questionnairesPathDisabled && refused && modelForm.questionnairesPathMessage" class="error-area">
              <exclamation-circle-outlined class="ic-error" />
              <span>{{ modelForm.questionnairesPathMessage }}</span>
            </div>
          </a-form-item>
          <div class="df aic fw">
            <a-form-item name="accessories" :label="$t('pages_merchant_129')" class="mt15">
              <div class="explain mb5">
                <span>{{ $t('pages_merchant_130') }}</span>
              </div>
              <div class="bi_upload">
                <upload-auto
                  ref="uploadRef"
                  v-model:file-content="modelForm.accessories"
                  accept=".jpg,.jpeg,.png,.pdf,.bmp,.xls,.xlsx,.csv,.doc,.docx,.rar,.zip"
                  type="INVITE"
                  list-type="picture"
                  back-type="array"
                  :explain="$t('pages_merchant_032')"
                  :max-count="10"
                />
              </div>
              <div v-if="!modelForm.accessoriesDisabled && refused && modelForm.accessoriesMessage" class="error-area">
                <exclamation-circle-outlined class="ic-error" />
                <span>{{ modelForm.accessoriesMessage }}</span>
              </div>
            </a-form-item>
            <div class="bi_desc_box">
              <div>{{ $t('pages_merchant_add_098') }}</div>
              <div>{{ $t('pages_merchant_add_099') }}</div>
              <div v-if="['HK'].includes(countryCode)">
                {{ $t('pages_merchant_add_100') }}<br>
                {{ $t('pages_merchant_add_101') }}<br>
                {{ $t('pages_merchant_add_102') }}<br>
              </div>
            </div>
          </div>
          <a-form-item name="supplyText" :label="$t('pages_merchant_131')" class="mt15">
            <a-textarea v-model:value="modelForm.approvePurpose" class="input-primary" :placeholder="$t('pages_merchant_132')" allow-clear />
            <div v-if="!modelForm.approvePurposeDisabled && refused && modelForm.approvePurposeMessage" class="error-area">
              <exclamation-circle-outlined class="ic-error" />
              <span>{{ modelForm.approvePurposeMessage }}</span>
            </div>
          </a-form-item>
          <a-form-item class="df aic mt15" name="isRiskCountryTransaction">
            <div>{{ $t('pages_merchant_add_095') }}</div>
            <a-radio-group v-model:value="modelForm.isRiskCountryTransaction" class="mt5" :disabled="modelForm.isRiskCountryTransactionDisabled">
              <a-radio value="1">{{ $t('pages_merchant_add_078') }}</a-radio>
              <a-radio value="0" class="ml12">{{ $t('pages_merchant_add_079') }}</a-radio>
            </a-radio-group>
            <div v-if="!modelForm.isRiskCountryTransactionDisabled && refused && modelForm.isRiskCountryTransactionMessage" class="error-area">
              <exclamation-circle-outlined class="ic-error" />
              <span>{{ modelForm.isRiskCountryTransactionMessage }}</span>
            </div>
          </a-form-item>
          <a-form-item v-if="modelForm.isRiskCountryTransaction==1" name="riskTransaction">
            <div class="mt5">{{ $t('pages_merchant_088') }}</div>
            <div class="df fw aic bi_area_box">
              <div v-for="(item, index) in modelForm.riskTransaction" :key="index" class="bi-country-area">
                <a-form-item :name="['riskTransaction', index, 'country']" :rules="rulesChange" class="mb15">
                  <a-select
                    v-model:value="item.country"
                    class="bi_input"
                    show-search
                    size="large"
                    option-filter-prop="label"
                    :placeholder="$t('pages_merchant_add_096')"
                    :disabled="modelForm.riskTransactionDisabled"
                  >
                    <a-select-option
                      v-for="country in countries"
                      :key="country.areaCode"
                      :value="country.areaCode"
                      :label="language === 'zh' ? country.areaChineseName : country.areaEnglishName"
                    >{{ language === 'zh' ? country.areaChineseName : country.areaEnglishName }}</a-select-option>
                  </a-select>
                </a-form-item>
                <a-form-item :name="['riskTransaction', index, 'name']" :rules="rulesBulr">
                  <a-input
                    v-model:value="item.name"
                    class="bi_input"
                    size="large"
                    allow-clear
                    :placeholder="$t('pages_merchant_add_097')"
                    :disabled="modelForm.riskTransactionDisabled"
                  />
                </a-form-item>
                <div v-if="!modelForm.riskTransactionDisabled" class="edit-area">
                  <plus-circle-filled v-if="index === 0" class="ic-add" @click="handleEdit(0)" />
                  <minus-circle-filled v-else class="ic-delete" @click="handleEdit(index)" />
                </div>
              </div>
            </div>
            <div v-if="!modelForm.riskTransactionDisabled && refused && modelForm.riskTransactionMessage" class="error-area">
              <exclamation-circle-outlined class="ic-error" />
              <span>{{ modelForm.riskTransactionMessage }}</span>
            </div>
          </a-form-item>
          <a-form-item name="checkOne" :rules="rulesChange" class="bi_check_title mt15">
            <a-checkbox v-model:checked="modelForm.checkOne">{{ $t('pages_merchant_091') }}</a-checkbox>
          </a-form-item>
          <a-form-item name="checkTwo" :rules="rulesChange" class="bi_check_title mt15">
            <a-checkbox v-model:checked="modelForm.checkTwo">{{ $t('pages_merchant_092') }}{{ themeData.saasMerNameZh }}</a-checkbox>
          </a-form-item>

          <a-form-item class="taC mt25 pb30">
            <a-button type="info" shape="round" class="minw130 mr10p" @click="lastBut">{{ $t('pages_merchant_add_054') }}</a-button>
            <a-button v-if="(colInfo.collectionProductSubType=='B2B2' && financeTrade) || pageStatus=='CUSTREJ'" :disabled="!modelForm.checkOne || !modelForm.checkTwo" type="primary" shape="round" class="minw130 mr10p" html-type="submit" :loading="loading">{{ $t('pages_merchant_add_104') }}</a-button>
            <a-button v-else type="primary" shape="round" class="minw130 mr10p" :disabled="!modelForm.checkOne || !modelForm.checkTwo" html-type="submit" :loading="loading">{{ $t('pages_merchant_add_103') }}</a-button>
          </a-form-item>
        </div>
      </div>
    </a-form>
  </div>
</template>
<script>

import { reactive, toRefs, inject, computed, nextTick, ref, watch } from 'vue'
import UploadAuto from '@/components/upload-auto'
import businessScope from './businessScope.json'
import sourceFunds from '@/common/sourceFunds'
import sourceWheres from '@/common/sourceWheres'
import i18n from '@/locale'
import { _user } from '@/api'
import { Modal } from 'ant-design-vue'
export default {
  name: 'PersonInfo',
  components: {
    'upload-auto': UploadAuto
  },
  props: {
    model: {
      type: Object,
      default: () => { return {} }
    },
    countries: {
      type: Array,
      default: () => { return [] }
    },
    countryCode: {
      type: String,
      default: () => { return '' }
    },
    refused: {
      type: Boolean,
      default: () => { return false }
    },
    colInfo: {
      type: Object,
      default: () => { return {} }
    },
    pageStatus: {
      type: String,
      default: () => { return '' }
    }
  },
  setup(props, ctx) {
    const uploadWRef = ref(null)
    const uploadHRef = ref(null)
    const uploadETPRef = ref(null)
    const state = reactive({
      loading: false,
      themeData: inject('$themeData'),
      language: localStorage.getItem('locale-language') || 'zh',
      rulesBulr: { required: true, message: i18n.global.t('pages_merchant_add_044'), trigger: 'bulr' },
      rulesChange: { required: true, message: i18n.global.t('pages_merchant_add_055'), trigger: 'change' },
      rulesUpload: { required: true, message: i18n.global.t('common_text_007') },
      alertNum: 0,
      financeTrade: false
    })
    state.modelForm = computed(() => {
      return props.model
    })
    state.scopeTradeList = computed(() => {
      if (state.language === 'zh') {
        businessScope.businessScopeTradeInZh.map(k => {
          const parts = k.title.split('.')
          const titleStr = parts.length > 1 ? parts.slice(1).join('.') : k.title
          return Object.assign(k, { titleNot: titleStr })
        })
        return businessScope.businessScopeTradeInZh
      } else {
        businessScope.businessScopeTradeInEn.map(k => {
          const parts = k.title.split('.')
          const titleStr = parts.length > 1 ? parts.slice(1).join('.') : k.title
          return Object.assign(k, { titleNot: titleStr })
        })
        return businessScope.businessScopeTradeInEn
      }
    })
    state.scopeServiceList = computed(() => {
      if (state.language === 'zh') {
        businessScope.businessScopeServiceZh.map(k => {
          const parts = k.title.split('.')
          const titleStr = parts.length > 1 ? parts.slice(1).join('.') : k.title
          return Object.assign(k, { titleNot: titleStr })
        })
        return businessScope.businessScopeServiceZh
      } else {
        businessScope.businessScopeServiceEn.map(k => {
          const parts = k.title.split('.')
          const titleStr = parts.length > 1 ? parts.slice(1).join('.') : k.title
          return Object.assign(k, { titleNot: titleStr })
        })
        return businessScope.businessScopeServiceEn
      }
    })
    state.sourceList = computed(() => {
      return sourceFunds
    })
    state.sourceWheresList = computed(() => {
      return sourceWheres
    })
    state.tradeAmountList = computed(() => {
      if (state.language === 'zh') {
        return businessScope.monthTradeAmountZh
      } else {
        return businessScope.monthTradeAmountEn
      }
    })

    nextTick(() => {
      const { businessWebsitePath, hisTransFilePath, questionnairesPath } = state.modelForm
      if (props.colInfo.collectionProductType === 'B2C' && businessWebsitePath.length !== 0 && uploadWRef.value) uploadWRef.value.fileListRefresh(businessWebsitePath)
      if (props.colInfo.collectionProductType === 'B2B' && hisTransFilePath.length !== 0 && uploadHRef.value) uploadHRef.value.fileListRefresh(hisTransFilePath)
      if (state.modelForm.isETradePlatform === '1' && questionnairesPath.length !== 0 && uploadETPRef.value) uploadETPRef.value.fileListRefresh(questionnairesPath)
    })
    const submit = () => {
      if (props.colInfo.collectionProductSubType === 'B2B2') { state.modelForm.businessBackgroundTradeInGoods = [] }
      if (props.colInfo.collectionProductSubType !== 'B2B2') { state.modelForm.businessBackgroundServiceTrade = [] }
      const data = (props.colInfo.collectionProductSubType === 'B2B2' && state.financeTrade) ? null : 'va'
      ctx.emit('submit', data)
    }
    const lastBut = () => {
      ctx.emit('next', '-')
    }
    const handleEdit = (currentIndex) => {
      if (currentIndex) state.modelForm.riskTransaction = state.modelForm.riskTransaction.filter((item, index) => index !== currentIndex)
      else state.modelForm.riskTransaction.push({ country: null, name: '' })
    }
    const initFun = () => {
    }
    watch(
      () => state.modelForm.businessBackgroundServiceTrade,
      (newValue) => {
        // 判断经营范围中是否包含 ‘金融 ’
        const financeScope = state.scopeServiceList.find(k => k.title === '3.金融')
        if (financeScope) {
          state.financeTrade = newValue && newValue.includes(financeScope.title)
        }
        if (props.colInfo.collectionProductSubType === 'B2B2' && state.financeTrade) {
          if (state.alertNum === 0) {
            state.alertNum = state.alertNum + 1
            Modal.info({
              content: i18n.global.t('pages_merchant_add_105')
            })
          }
        } else {
          state.alertNum = 0
        }
      }, { immediate: true }
    )
    const downloadTemplate = async (name) => {
      const res = await _user.exportMerchantTemplate({ name })
      if (res.data.succ) window.open(res.data.data)
    }
    initFun()
    return {
      uploadWRef,
      uploadHRef,
      uploadETPRef,
      submit,
      lastBut,
      handleEdit,
      initFun,
      downloadTemplate,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
.business-info {
  display: flex;
  justify-content: space-around;
  width: 90%;
  margin: auto;
  /deep/.ant-form-item-label{
    padding-bottom: 2px !important;
  }
  .ant-form-item{
    margin-bottom: 5px !important;
  }
  .bi_input{
    max-width: 1120px !important;
    border-radius: 5px !important;
    font-size: 15px !important;
    padding-top: 3px;
    padding-bottom: 3px;
    /deep/.ant-input-lg{
      font-size: 14px !important;
    }
    /deep/.ant-select-selector{
      font-size: 14px !important;
      border-radius: 5px !important;
    }
    /deep/.ant-select-selection-placeholder{
      font-size: 14px !important;
    }
  }
  .bi_upload{
    max-width: 420px !important;
    /deep/.ant-upload{
      padding: 2px 0px  5px 0px !important;
    }
    /deep/.ant-upload-img{
      margin-bottom: 1px !important;
    }
    /deep/.ant-upload-hint{
      margin-top: 2px !important;
    }
  }
}

.bi_area_box{
  max-width: 1060px !important;
}

.bi-country-area {
  margin-top: 10px;
  width: 330px;
  margin-right: 20px;
  .edit-area {
    text-align: right;
    padding-right: 12px;
    margin-top: -6px;
    .ic-delete, .ic-add  {
      color: @main-color;
      cursor: pointer;
      font-size: 20px;
    }
  }
}
.bi_check_title{
  max-width: 900px !important;
}
.bi_desc_box{
  max-width: 400px;
  margin-left: 30px;
  font-size: 12px;
  line-height: 20px;
  padding-top: 15px;
}
@media screen and (max-width: 768px) {
  .bi-country-area{
    width: 100% !important;
    margin-right: 0px !important;
  }
  .bi_check_title{
    max-width: 100%;
  }
  .bi_desc_box{
    margin-left: 0px;
  }
}
.error-area {
    padding: 5px 10px 5px 12px;
    border-radius: 5px;
    color: @error-color;
    background: #fef0f0;
    margin-top: 5px;
    text-align: left;
    word-break: break-word;
    .ic-error {
      font-size: 16px;
      margin-right: 12px;
    }
    }
</style>
