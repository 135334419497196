<template>
  <div id="guidePage">
    <a-form
      ref="step1Ref"
      class="ant-round-form material-company-register-form"
      layout="vertical"
      :model="modelForm"
      :hide-required-mark="true"
      @finish="submit"
    >
      <div class="business-info">
        <div class="cp_content_box">
          <div class="fwb fs20">{{ $t('pages_merchant_add_063') }}</div>
          <div class="fs14 mt5">{{ $t('pages_merchant_add_064') }}</div>
          <div class="df fw">
            <div class="mt25 bi_right">
              <a-form-item v-if="countryCode === 'CN'" name="businessLicensePath" :rules="rulesUpload" class="bi_upload">
                <div>{{ $t('pages_merchant_add_016') }}</div>
                <div class="explain mb10">
                  <span>{{ $t('pages_merchant_035') }}</span>
                  <a :href="require('/src/assets/files/yingYeZhiZhao.png')" target="_blank">{{ $t('pages_merchant_046') }}</a>
                  <span>{{ $t('pages_merchant_047') }}{{ $t('pages_merchant_007') }}{{ $t('pages_merchant_048') }}</span>
                </div>
                <upload-auto-two
                  ref="uploadBRef"
                  v-model:file-content="modelForm.businessLicensePath"
                  accept=".jpg,.jpeg,.png,.pdf,.bmp,.zip,.rar"
                  type="INVITE"
                  list-type="picture"
                  back-type="array"
                  :disabled="modelForm.businessLicensePathDisabled"
                  :explain="$t('pages_merchant_032')"
                  :max-count="1"
                  @suceefun="businessOcrFun(modelForm.businessLicensePath)"
                />
                <div v-if="!modelForm.businessLicensePathDisabled && refused && modelForm.businessLicensePathMessage" class="error-area">
                  <exclamation-circle-outlined class="ic-error" />
                  <span>{{ modelForm.businessLicensePathMessage }}</span>
                </div>
              </a-form-item>
              <div v-if="countryCode === 'HK'">
                <a-form-item name="businessRegistrationCertificatePath" :rules="rulesUpload" class="bi_upload">
                  <div>{{ $t('pages_merchant_add_020') }}</div>
                  <div class="explain mb10">
                    <span>{{ $t('pages_merchant_035') }}</span>
                    <a :href="require('/src/assets/files/shangYeDJZ.png')" target="_blank">{{ $t('pages_merchant_046') }}</a>
                    <span>{{ $t('pages_merchant_047') }}{{ $t('pages_merchant_add_020') }}{{ $t('pages_merchant_048') }}</span>
                  </div>
                  <upload-auto
                    ref="uploadBRCRef"
                    v-model:file-content="modelForm.businessRegistrationCertificatePath"
                    accept=".jpg,.jpeg,.png,.pdf,.bmp,.zip,.rar"
                    type="INVITE"
                    list-type="picture"
                    back-type="array"
                    :disabled="modelForm.businessRegistrationCertificatePathDisabled"
                    :explain="$t('pages_merchant_032')"
                    :max-count="5"
                  />
                  <div v-if="!modelForm.businessRegistrationCertificatePathDisabled && refused && modelForm.businessRegistrationCertificatePathMessage" class="error-area">
                    <exclamation-circle-outlined class="ic-error" />
                    <span>{{ modelForm.businessRegistrationCertificatePathMessage }}</span>
                  </div>
                </a-form-item>
                <a-form-item name="incorporationPath" :rules="rulesUpload" class="bi_upload">
                  <div class="mt10">{{ $t('pages_merchant_add_019') }}</div>
                  <div class="explain mb10">
                    <span>{{ $t('pages_merchant_035') }}</span>
                    <a :href="require('/src/assets/files/zhuCeZhengMing.png')" target="_blank">{{ $t('pages_merchant_046') }}</a>
                    <span>{{ $t('pages_merchant_047') }}{{ $t('pages_merchant_add_019') }}{{ $t('pages_merchant_048') }}</span>
                  </div>
                  <upload-auto
                    ref="uploadIRef"
                    v-model:file-content="modelForm.incorporationPath"
                    accept=".jpg,.jpeg,.png,.pdf,.bmp,.zip,.rar"
                    type="INVITE"
                    list-type="picture"
                    back-type="array"
                    :disabled="modelForm.incorporationPathDisabled"
                    :explain="$t('pages_merchant_032')"
                    :max-count="5"
                  />
                  <div v-if="!modelForm.incorporationPathDisabled && refused && modelForm.incorporationPathMessage" class="error-area">
                    <exclamation-circle-outlined class="ic-error" />
                    <span>{{ modelForm.incorporationPathMessage }}</span>
                  </div>
                </a-form-item>
                <a-form-item name="nnc1Path" :rules="rulesUpload" class="bi_upload">
                  <div class="mt10">{{ $t('pages_merchant_add_021') }}</div>
                  <div class="explain mb10">
                    <span>{{ $t('pages_merchant_035') }}</span>
                    <a :href="require('/src/assets/files/faTuanCLB.png')" target="_blank">{{ $t('pages_merchant_046') }}</a>
                    <span>{{ $t('pages_merchant_047') }}{{ $t('pages_merchant_add_021') }}{{ $t('pages_merchant_048') }}</span>
                  </div>
                  <upload-auto
                    ref="uploadNNRef"
                    v-model:file-content="modelForm.nnc1Path"
                    accept=".jpg,.jpeg,.png,.pdf,.bmp,.zip,.rar"
                    type="INVITE"
                    list-type="picture"
                    back-type="array"
                    :disabled="modelForm.nnc1PathDisabled"
                    :explain="$t('pages_merchant_032')"
                    :max-count="5"
                  />
                  <div v-if="!modelForm.nnc1PathDisabled && refused && modelForm.nnc1PathMessage" class="error-area">
                    <exclamation-circle-outlined class="ic-error" />
                    <span>{{ modelForm.nnc1PathMessage }}</span>
                  </div>
                </a-form-item>
                <a-form-item name="nar1Path1" :rules="yearStatus?rulesUpload:[]" class="bi_upload">
                  <div class="mt10">{{ $t('pages_merchant_add_022') }}</div>
                  <div class="explain mb10">
                    <span>{{ $t('pages_merchant_035') }}</span>
                    <a :href="require('/src/assets/files/zhouNiannNianbao.png')" target="_blank">{{ $t('pages_merchant_046') }}</a>
                    <span>{{ $t('pages_merchant_047') }}{{ $t('pages_merchant_add_022') }}{{ $t('pages_merchant_048') }}</span>
                  </div>
                  <upload-auto
                    ref="uploadNARef"
                    v-model:file-content="modelForm.nar1Path1"
                    accept=".jpg,.jpeg,.png,.pdf,.bmp,.zip,.rar"
                    type="INVITE"
                    list-type="picture"
                    back-type="array"
                    :disabled="modelForm.nar1Path1Disabled"
                    :explain="$t('pages_merchant_032')"
                    :max-count="5"
                  />
                  <div v-if="!modelForm.nar1Path1Disabled && refused && modelForm.nar1Path1Message" class="error-area">
                    <exclamation-circle-outlined class="ic-error" />
                    <span>{{ modelForm.nar1Path1Message }}</span>
                  </div>
                </a-form-item>
                <a-form-item name="mermorandumPath" class="bi_upload">
                  <div class="mt10">{{ $t('pages_merchant_add_061') }}{{ $t('pages_merchant_add_062') }}</div>
                  <div class="explain mb10">
                    <span>{{ $t('pages_merchant_035') }}</span>
                    <a :href="require('/src/assets/files/zhangCheng.png')" target="_blank">{{ $t('pages_merchant_046') }}</a>
                    <span>{{ $t('pages_merchant_047') }}{{ $t('pages_merchant_add_061') }}{{ $t('pages_merchant_048') }}</span>
                  </div>
                  <upload-auto
                    ref="uploadMRef"
                    v-model:file-content="modelForm.mermorandumPath"
                    accept=".jpg,.jpeg,.png,.pdf,.bmp,.zip,.rar"
                    type="INVITE"
                    list-type="picture"
                    back-type="array"
                    :disabled="modelForm.mermorandumPathDisabled"
                    :explain="$t('pages_merchant_032')"
                    :max-count="5"
                  />
                  <div v-if="!modelForm.mermorandumPathDisabled && refused && modelForm.mermorandumPathMessage" class="error-area">
                    <exclamation-circle-outlined class="ic-error" />
                    <span>{{ modelForm.mermorandumPathMessage }}</span>
                  </div>
                </a-form-item>
              </div>
              <div v-if="!['CN','HK'].includes(countryCode)">
                <a-form-item name="businessRegistrationFilePath" :rules="rulesUpload" class="bi_upload">
                  <div class="mt10">{{ $t('pages_merchant_add_065') }}</div>
                  <div class="explain mb10">
                    <span>{{ $t('pages_merchant_035') }}</span>
                    <a v-if="countryCode=='GB'" :href="require('/src/assets/files/zhuCheShuGB.png')" target="_blank">{{ $t('pages_merchant_046') }}</a>
                    <a v-else-if="countryCode=='SG'" :href="require('/src/assets/files/zhuCheShuSG.png')" target="_blank">{{ $t('pages_merchant_046') }}</a>
                    <a v-else :href="require('/src/assets/files/zhuCheShuUS.png')" target="_blank">{{ $t('pages_merchant_046') }}</a>
                    <span>{{ $t('pages_merchant_047') }}{{ $t('pages_merchant_add_065') }}{{ $t('pages_merchant_048') }}</span>
                  </div>
                  <upload-auto
                    ref="uploadBRRef"
                    v-model:file-content="modelForm.businessRegistrationFilePath"
                    accept=".jpg,.jpeg,.png,.pdf,.bmp,.zip,.rar"
                    type="INVITE"
                    list-type="picture"
                    back-type="array"
                    :disabled="modelForm.businessRegistrationFilePathDisabled"
                    :explain="$t('pages_merchant_032')"
                    :max-count="5"
                  />
                  <div v-if="!modelForm.businessRegistrationFilePathDisabled && refused && modelForm.businessRegistrationFilePathMessage" class="error-area">
                    <exclamation-circle-outlined class="ic-error" />
                    <span>{{ modelForm.businessRegistrationFilePathMessage }}</span>
                  </div>
                </a-form-item>
                <a-form-item name="mermorandumPath" class="bi_upload">
                  <div class="mt10">{{ $t('pages_merchant_add_061') }}{{ $t('pages_merchant_add_062') }}</div>
                  <div class="explain mb10">
                    <span>{{ $t('pages_merchant_035') }}</span>
                    <a :href="require('/src/assets/files/zhangCheng.png')" target="_blank">{{ $t('pages_merchant_046') }}</a>
                    <span>{{ $t('pages_merchant_047') }}{{ $t('pages_merchant_add_061') }}{{ $t('pages_merchant_048') }}</span>
                  </div>
                  <upload-auto
                    ref="uploadMRef"
                    v-model:file-content="modelForm.mermorandumPath"
                    accept=".jpg,.jpeg,.png,.pdf,.bmp,.zip,.rar"
                    type="INVITE"
                    list-type="picture"
                    back-type="array"
                    :disabled="modelForm.mermorandumPathDisabled"
                    :explain="$t('pages_merchant_032')"
                    :max-count="5"
                  />
                  <div v-if="!modelForm.mermorandumPathDisabled && refused && modelForm.mermorandumPathMessage" class="error-area">
                    <exclamation-circle-outlined class="ic-error" />
                    <span>{{ modelForm.mermorandumPathMessage }}</span>
                  </div>
                </a-form-item>
              </div>
            </div>
            <div>
              <div :class="['cp_tip','mt15']">
                <div v-if="txtSpin" class="ci_spin df aic jusa">
                  <div><a-spin /></div>
                </div>
                <a-form-item v-if="countryCode=='CN'" name="businessLicenseCertificateNumber" :label="$t('pages_merchant_add_047')" :rules="rulesBulr">
                  <a-input
                    v-model:value="modelForm.businessLicenseCertificateNumber"
                    class="bi_input"
                    size="large"
                    allow-clear
                    :disabled="modelForm.businessLicenseCertificateNumberDisabled && modelForm.businessLicensePathDisabled"
                    :placeholder="$t('pages_merchant_add_044')"
                  />
                  <div v-if="!modelForm.businessLicenseCertificateNumberDisabled && refused && modelForm.businessLicenseCertificateNumberMessage" class="error-area">
                    <exclamation-circle-outlined class="ic-error" />
                    <span>{{ modelForm.businessLicenseCertificateNumberMessage }}</span>
                  </div>
                </a-form-item>
                <a-form-item v-if="countryCode=='CN'" name="merchantName" :label="$t('pages_merchant_add_045')" :rules="rulesBulr">
                  <a-input
                    v-model:value="modelForm.merchantName"
                    class="bi_input"
                    size="large"
                    allow-clear
                    :disabled="modelForm.merchantNameDisabled && modelForm.businessLicensePathDisabled"
                    :placeholder="$t('pages_merchant_add_044')"
                  />
                  <div v-if="!modelForm.merchantNameDisabled && refused && modelForm.merchantNameMessage" class="error-area">
                    <exclamation-circle-outlined class="ic-error" />
                    <span>{{ modelForm.merchantNameMessage }}</span>
                  </div>
                </a-form-item>
                <a-form-item v-if="countryCode=='HK'" name="businessRegistrationNumber" :label="$t('pages_merchant_add_107')" :rules="rulesBulr">
                  <a-input
                    v-model:value="modelForm.businessRegistrationNumber"
                    class="bi_input"
                    size="large"
                    allow-clear
                    :disabled="modelForm.businessRegistrationNumberDisabled"
                    :placeholder="$t('pages_merchant_add_044')"
                  />
                  <div v-if="!modelForm.businessRegistrationNumberDisabled && refused && modelForm.businessRegistrationNumberMessage" class="error-area">
                    <exclamation-circle-outlined class="ic-error" />
                    <span>{{ modelForm.businessRegistrationNumberMessage }}</span>
                  </div>
                </a-form-item>
                <a-form-item v-if="countryCode=='HK'" name="companyCertificateCINumber" :label="$t('pages_merchant_add_106')" :rules="rulesBulr">
                  <a-input
                    v-model:value="modelForm.companyCertificateCINumber"
                    class="bi_input"
                    size="large"
                    allow-clear
                    :disabled="modelForm.companyCertificateCINumberDisabled"
                    :placeholder="$t('pages_merchant_add_044')"
                  />
                  <div v-if="!modelForm.companyCertificateCINumberDisabled && refused && modelForm.companyCertificateCINumberMessage" class="error-area">
                    <exclamation-circle-outlined class="ic-error" />
                    <span>{{ modelForm.companyCertificateCINumberMessage }}</span>
                  </div>
                </a-form-item>
                <a-form-item v-if="countryCode!='CN' && countryCode!='HK'" name="companyRegistrationCertificateNumber" :label="$t('pages_merchant_add_108')" :rules="rulesBulr">
                  <a-input
                    v-model:value="modelForm.companyRegistrationCertificateNumber"
                    class="bi_input"
                    size="large"
                    allow-clear
                    :disabled="modelForm.companyRegistrationCertificateNumberDisabled"
                    :placeholder="$t('pages_merchant_add_044')"
                  />
                  <div v-if="!modelForm.companyRegistrationCertificateNumberDisabled && refused && modelForm.companyRegistrationCertificateNumberMessage" class="error-area">
                    <exclamation-circle-outlined class="ic-error" />
                    <span>{{ modelForm.companyRegistrationCertificateNumberMessage }}</span>
                  </div>
                </a-form-item>
                <a-form-item name="merchantNameEn" :label="$t('pages_merchant_add_046')" :rules="rulesBulr">
                  <a-input
                    v-model:value="modelForm.merchantNameEn"
                    class="bi_input"
                    size="large"
                    allow-clear
                    :disabled="modelForm.merchantNameEnDisabled && modelForm.businessLicensePathDisabled"
                    :placeholder="$t('pages_merchant_add_044')"
                  />
                  <div v-if="!modelForm.merchantNameEnDisabled && refused && modelForm.merchantNameEnMessage" class="error-area">
                    <exclamation-circle-outlined class="ic-error" />
                    <span>{{ modelForm.merchantNameEnMessage }}</span>
                  </div>
                </a-form-item>
                <a-form-item name="establishDate" :label="$t('pages_merchant_add_048')" :rules="rulesChange">
                  <a-date-picker
                    v-model:value="modelForm.establishDate"
                    class="bi_input"
                    format="YYYY-MM-DD"
                    value-format="YYYY-MM-DD"
                    :disabled="modelForm.establishDateDisabled && modelForm.businessLicensePathDisabled"
                    @change="(e)=>dateChange(e,'establishDate')"
                  />
                  <div v-if="!modelForm.establishDateDisabled && refused && modelForm.establishDateMessage" class="error-area">
                    <exclamation-circle-outlined class="ic-error" />
                    <span>{{ modelForm.establishDateMessage }}</span>
                  </div>
                </a-form-item>
                <a-form-item name="brExpiryDate" :label="$t('pages_merchant_add_049')" :rules="rulesChange">
                  <div class="df aic">
                    <a-date-picker
                      v-model:value="modelForm.brExpiryDate"
                      class="bi_input"
                      format="YYYY-MM-DD"
                      value-format="YYYY-MM-DD"
                      :disabled="(modelForm.brExpiryDateDisabled || modelForm.isBrExpiry) && modelForm.businessLicensePathDisabled"
                    />
                    <a-form-item-rest>
                      <a-checkbox v-model:checked="modelForm.isBrExpiry" :disabled="modelForm.brExpiryDateDisabled && modelForm.businessLicensePathDisabled" class="wsn ml10" @change="(e)=>parameChange(e.target.checked?'2099-12-31':'','brExpiryDate')">{{ $t('pages_merchant_add_073') }}</a-checkbox>
                    </a-form-item-rest>
                  </div>
                  <div v-if="!modelForm.brExpiryDateDisabled && refused && modelForm.brExpiryDateMessage" class="error-area">
                    <exclamation-circle-outlined class="ic-error" />
                    <span>{{ modelForm.brExpiryDateMessage }}</span>
                  </div>
                </a-form-item>
                <a-form-item name="registrationAddress" :label="$t('pages_merchant_add_050')" :rules="rulesBulr">
                  <a-input
                    v-model:value="modelForm.registrationAddress"
                    class="bi_input"
                    size="large"
                    allow-clear
                    :disabled="modelForm.registrationAddressDisabled && modelForm.businessLicensePathDisabled"
                    :placeholder="$t('pages_merchant_add_044')"
                    @change="addressReg"
                  />
                  <div v-if="!modelForm.registrationAddressDisabled && refused && modelForm.registrationAddressMessage" class="error-area">
                    <exclamation-circle-outlined class="ic-error" />
                    <span>{{ modelForm.registrationAddressMessage }}</span>
                  </div>
                </a-form-item>
                <a-radio-group v-model:value="modelForm.isAddress" :disabled="(modelForm.registrationAddressDisabled || modelForm.mailingAddressDisabled) && modelForm.businessLicensePathDisabled" @change="addressReg">
                  <a-radio :value="true">{{ $t('pages_merchant_add_109') }}</a-radio>
                  <a-radio :value="false">{{ $t('pages_merchant_add_057') }}</a-radio>
                </a-radio-group>
                <div v-if="!modelForm.isAddress">
                  <a-form-item name="mailingAddress" :label="$t('pages_merchant_add_051')" :rules="rulesBulr">
                    <a-input
                      v-model:value="modelForm.mailingAddress"
                      class="bi_input"
                      size="large"
                      allow-clear
                      :disabled="modelForm.mailingAddressDisabled && modelForm.businessLicensePathDisabled"
                      :placeholder="$t('pages_merchant_add_044')"
                    />
                    <div v-if="!modelForm.mailingAddressDisabled && refused && modelForm.mailingAddressMessage" class="error-area">
                      <exclamation-circle-outlined class="ic-error" />
                      <span>{{ modelForm.mailingAddressMessage }}</span>
                    </div>
                  </a-form-item>
                  <a-form-item name="proofOfRealBusinessAddress" :rules="modelForm.isAddress?[]:rulesChange" class="bi_upload">
                    <div class="mb5 fwn">{{ $t('pages_merchant_add_052') }}</div>
                    <upload-auto
                      ref="uploadAddressBRef"
                      v-model:file-content="modelForm.proofOfRealBusinessAddress"
                      accept=".jpg,.jpeg,.png,.pdf,.bmp"
                      type="INVITE"
                      list-type="picture"
                      back-type="array"
                      :disabled="modelForm.proofOfRealBusinessAddressDisabled && modelForm.businessLicensePathDisabled"
                      :explain="$t('pages_merchant_032')"
                      :max-count="5"
                    />
                    <div v-if="!modelForm.proofOfRealBusinessAddressDisabled && refused && modelForm.proofOfRealBusinessAddressMessage" class="error-area">
                      <exclamation-circle-outlined class="ic-error" />
                      <span>{{ modelForm.proofOfRealBusinessAddressMessage }}</span>
                    </div>
                  </a-form-item>
                </div>
                <a-form-item name="companyAddressType" :label="$t('pages_merchant_add_053')" :rules="rulesChange">
                  <a-select
                    v-model:value="modelForm.companyAddressType"
                    class="bi_input"
                    option-label-prop="label"
                    :disabled="modelForm.companyAddressTypeDisabled && modelForm.businessLicensePathDisabled"
                  >
                    <a-select-option
                      v-for="item in companyAddressAll"
                      :key="item.value"
                      :value="item.value"
                      :label="item.key"
                    >{{ item.key }}</a-select-option>
                  </a-select>
                  <div v-if="!modelForm.companyAddressTypeDisabled && refused && modelForm.companyAddressTypeMessage" class="error-area">
                    <exclamation-circle-outlined class="ic-error" />
                    <span>{{ modelForm.companyAddressTypeMessage }}</span>
                  </div>
                </a-form-item>
              </div>
            </div>
          </div>
          <div>
            <a-form-item class="mt20" name="isParentCertificate" :rules="rulesChange">
              <div class="fwb mb5">{{ $t('pages_merchant_021') }}</div>
              <a-radio-group v-model:value="modelForm.isParentCertificate" :disabled="modelForm.isParentCertificateDisabled" @change="loadPath">
                <a-radio value="1">{{ $t('pages_merchant_022') }}</a-radio>
                <a-radio value="0">{{ $t('pages_merchant_023') }}</a-radio>
              </a-radio-group>
              <div v-if="!modelForm.isParentCertificateDisabled && refused && modelForm.isParentCertificateMessage" class="error-area">
                <exclamation-circle-outlined class="ic-error" />
                <span>{{ modelForm.isParentCertificateMessage }}</span>
              </div>
            </a-form-item>
            <div v-if="modelForm.isParentCertificate === '1'" class="mt10">
              <a-form-item name="parentCertificate">
                <div>{{ $t('pages_merchant_024') }}</div>
                <span class="explain">{{ $t('pages_merchant_025') }}</span>
                <div class="df fw aic bi_area_box">
                  <div
                    v-for="(item, index) in modelForm.parentCertificate"
                    :key="index"
                    class="bi-country-area"
                  >
                    <a-form-item
                      :name="['parentCertificate', index, 'country']"
                      :rules="{ required: true, message: $t('pages_merchant_026') }"
                      style="margin-bottom: 12px"
                    >
                      <a-select
                        v-model:value="item.country"
                        class="bi_input"
                        show-search
                        option-filter-prop="label"
                        :placeholder="$t('pages_merchant_026')"
                        :disabled="modelForm.parentCertificateDisabled"
                      >
                        <a-select-option
                          v-for="country in countries"
                          :key="country.areaCode"
                          :value="country.areaCode"
                          :label="language === 'zh' ? country.areaChineseName : country.areaEnglishName"
                        >{{ language === 'zh' ? country.areaChineseName : country.areaEnglishName }}</a-select-option>
                      </a-select>
                    </a-form-item>
                    <a-form-item
                      :name="['parentCertificate', index, 'name']"
                      :rules="{ required: true, message: $t('pages_merchant_027') }"
                    >
                      <a-input
                        v-model:value="item.name"
                        class="bi_input"
                        size="large"
                        allow-clear
                        :placeholder="$t('pages_merchant_027')"
                        :disabled="modelForm.parentCertificateDisabled"
                      />
                    </a-form-item>
                    <div v-if="!modelForm.parentCertificateDisabled" class="edit-area">
                      <plus-circle-filled v-if="index === 0" class="ic-add" @click="handleEdit(0)" />
                      <minus-circle-filled v-else class="ic-delete" @click="handleEdit(index)" />
                    </div>
                  </div>
                </div>
                <div v-if="!modelForm.parentCertificateDisabled && refused && modelForm.parentCertificateMessage" class="error-area">
                  <exclamation-circle-outlined class="ic-error" />
                  <span>{{ modelForm.parentCertificateMessage }}</span>
                </div>
              </a-form-item>
              <div class="df fw">
                <a-form-item
                  name="ownerShipPath"
                  class="bi_upload bi_right"
                  :rules="rulesUpload"
                >
                  <div class="explain mb10">
                    <div>{{ $t('pages_merchant_028') }}</div>
                    <div>{{ $t('pages_merchant_029') }}</div>
                    <div>{{ $t('pages_merchant_030') }}</div>
                    <div>{{ $t('pages_merchant_031') }}</div>
                    <a
                      style="display: inline-block; margin-top: 12px; font-weight: 500"
                      @click="downOwnerPath('equity')"
                    >{{ $t('pages_merchant_033') }}</a>
                  </div>
                  <upload-auto
                    ref="uploadORef"
                    v-model:file-content="modelForm.ownerShipPath"
                    accept=".jpg,.jpeg,.png,.pdf,.bmp"
                    type="INVITE"
                    list-type="picture"
                    back-type="array"
                    :disabled="modelForm.ownerShipPathDisabled"
                    :explain="$t('pages_merchant_032')"
                    :max-count="5"
                  />
                  <div v-if="!modelForm.ownerShipPathDisabled && refused && modelForm.ownerShipPathMessage" class="error-area">
                    <exclamation-circle-outlined class="ic-error" />
                    <span>{{ modelForm.ownerShipPathMessage }}</span>
                  </div>
                </a-form-item>
                <a-form-item
                  class="mt10 bi_upload"
                  name="parentCertificatePath"
                  :rules="{ required: isRequire, message: $t('common_text_007') }"
                >
                  <div>{{ $t('pages_merchant_034') }}</div>
                  <div class="explain mb10">
                    <span>{{ $t('pages_merchant_035') }}</span>
                    <a :href="require('/src/assets/files/weiJingQunDao.png')" target="_blank">{{ $t('pages_merchant_036') }}</a> /
                    <a :href="require('/src/assets/files/kaiMan.png')" target="_blank">{{ $t('pages_merchant_037') }}</a> /
                    <a :href="require('/src/assets/files/incumbency.png')" target="_blank">{{ $t('pages_merchant_038') }}</a>
                    <span>{{ $t('pages_merchant_039') }}</span>
                  </div>
                  <upload-auto
                    ref="uploadPRef"
                    v-model:file-content="modelForm.parentCertificatePath"
                    accept=".jpg,.jpeg,.png,.pdf,.bmp"
                    type="INVITE"
                    list-type="picture"
                    back-type="array"
                    :disabled="modelForm.parentCertificateDisabled"
                    :explain="$t('pages_merchant_032')"
                    :max-count="5"
                  />
                  <div v-if="!modelForm.parentCertificateDisabled && refused && modelForm.parentCertificateMessage" class="error-area">
                    <exclamation-circle-outlined class="ic-error" />
                    <span>{{ modelForm.parentCertificateMessage }}</span>
                  </div>
                </a-form-item>
              </div>
            </div>
          </div>
          <div>
            <a-form-item name="isResides" class="mt20" :rules="rulesChange">
              <div class="fwb">{{ $t('pages_merchant_041') }}</div>
              <a-radio-group v-model:value="modelForm.isResides" :disabled="modelForm.isResidesDisabled">
                <a-radio value="1">{{ $t('pages_merchant_022') }}</a-radio>
                <a-radio value="0">{{ $t('pages_merchant_023') }}</a-radio>
              </a-radio-group>
              <div v-if="!modelForm.isResidesDisabled && refused && modelForm.isResidesMessage" class="error-area">
                <exclamation-circle-outlined class="ic-error" />
                <span>{{ modelForm.isResidesMessage }}</span>
              </div>
            </a-form-item>
            <a-form-item v-if="modelForm.isResides === '1'" name="resides">
              <div class="mt5">{{ $t('pages_merchant_042') }}</div>
              <span class="explain">{{ $t('pages_merchant_043') }}</span>
              <div class="df fw aic bi_area_box">
                <div
                  v-for="(item, index) in modelForm.resides"
                  :key="index"
                  class="bi-country-area"
                >
                  <a-form-item
                    :name="['resides', index, 'country']"
                    :rules="{ required: true, message: $t('pages_merchant_044') }"
                    style="margin-bottom: 12px"
                  >
                    <a-select
                      v-model:value="item.country"
                      class="bi_input"
                      show-search
                      option-filter-prop="label"
                      :placeholder="$t('pages_merchant_044')"
                      :disabled="modelForm.residesDisabled"
                    >
                      <a-select-option
                        v-for="country in countries"
                        :key="country.areaCode"
                        :value="country.areaCode"
                        :label="language === 'zh' ? country.areaChineseName : country.areaEnglishName"
                      >{{ language === 'zh' ? country.areaChineseName : country.areaEnglishName }}</a-select-option>
                    </a-select>
                  </a-form-item>
                  <a-form-item
                    :name="['resides', index, 'name']"
                    :rules="{ required: true, message: $t('pages_merchant_045') }"
                  >
                    <a-input
                      v-model:value="item.name"
                      class="bi_input"
                      size="large"
                      allow-clear
                      :placeholder="$t('pages_merchant_045')"
                      :disabled="modelForm.residesDisabled"
                    />
                  </a-form-item>
                  <div v-if="!modelForm.residesDisabled" class="edit-area">
                    <plus-circle-filled v-if="index === 0" class="ic-add" @click="handleSonEdit(0)" />
                    <minus-circle-filled v-else class="ic-delete" @click="handleSonEdit(index)" />
                  </div>
                </div>
              </div>
              <div v-if="!modelForm.residesDisabled && refused && modelForm.residesMessage" class="error-area">
                <exclamation-circle-outlined class="ic-error" />
                <span>{{ modelForm.residesMessage }}</span>
              </div>
            </a-form-item>
          </div>
          <a-form-item class="taC mt25 pb30">
            <a-button type="info" shape="round" class="minw130 mr10p" @click="lastBut">{{ $t('pages_merchant_add_054') }}</a-button>
            <a-button html-type="submit" type="primary" shape="round" class="minw130">{{ $t('pages_merchant_020') }}</a-button>
          </a-form-item>
        </div>
      </div>
    </a-form>
  </div>
</template>
<script>

import { _user } from '@/api'
import { ref, reactive, toRefs, inject, computed, nextTick } from 'vue'
import UploadAuto from '@/components/upload-auto'
import uploadAutoTwo from '@/components/upload-auto-two'
import i18n from '@/locale'
export default {
  name: 'CompanyInfo',
  components: {
    'upload-auto': UploadAuto,
    'upload-auto-two': uploadAutoTwo
  },
  props: {
    model: {
      type: Object,
      default: () => { return {} }
    },
    countries: {
      type: Array,
      default: () => { return [] }
    },
    countryCode: {
      type: String,
      default: () => { return '' }
    },
    refused: {
      type: Boolean,
      default: () => { return false }
    }
  },
  setup(props, ctx) {
    const uploadBRef = ref(null)
    const uploadAddressBRef = ref(null)
    const uploadBRCRef = ref(null)
    const uploadIRef = ref(null)
    const uploadNNRef = ref(null)
    const uploadNARef = ref(null)
    const uploadMRef = ref(null)
    const uploadBRRef = ref(null)
    const uploadORef = ref(null)
    const uploadPRef = ref(null)
    const state = reactive({
      themeData: inject('$themeData'),
      language: localStorage.getItem('locale-language') || 'zh',
      rulesBulr: { required: true, message: i18n.global.t('pages_merchant_add_044'), trigger: 'bulr' },
      rulesChange: { required: true, message: i18n.global.t('pages_merchant_add_055'), trigger: 'change' },
      rulesUpload: { required: true, message: i18n.global.t('common_text_007') },
      companyAddressAll: [],
      yearStatus: false,
      txtSpin: false
    })
    state.isRequire = computed(() => {
      return state.modelForm.parentCertificate.some(item => item.country !== 'CN')
    })
    state.modelForm = computed(() => {
      return props.model
    })
    const submit = () => {
      ctx.emit('next', '+')
    }
    const lastBut = () => {
      ctx.emit('next', '-')
    }
    nextTick(() => {
      const { businessLicensePath, businessRegistrationCertificatePath, incorporationPath, nnc1Path, nar1Path1, mermorandumPath, proofOfRealBusinessAddress, businessRegistrationFilePath } = state.modelForm
      if (props.countryCode === 'CN' && businessLicensePath.length !== 0 && uploadBRef.value) uploadBRef.value.fileListRefresh(businessLicensePath)
      if (proofOfRealBusinessAddress.length !== 0 && uploadAddressBRef.value) uploadAddressBRef.value.fileListRefresh(proofOfRealBusinessAddress)
      if (props.countryCode === 'HK') {
        if (businessRegistrationCertificatePath.length !== 0 && uploadBRCRef.value) { uploadBRCRef.value.fileListRefresh(businessRegistrationCertificatePath) }
        if (incorporationPath.length !== 0 && uploadIRef.value) { uploadIRef.value.fileListRefresh(incorporationPath) }
        if (nnc1Path.length !== 0 && uploadNNRef.value) { uploadNNRef.value.fileListRefresh(nnc1Path) }
        if (nar1Path1.length !== 0 && uploadNARef.value) { uploadNARef.value.fileListRefresh(nar1Path1) }
        if (mermorandumPath.length !== 0 && uploadMRef.value) { uploadMRef.value.fileListRefresh(mermorandumPath) }
      }
      if (!['HK', 'CN'].includes(props.countryCode)) {
        if (businessRegistrationFilePath.length !== 0 && uploadBRRef.value) { uploadBRRef.value.fileListRefresh(businessRegistrationFilePath) }
        if (mermorandumPath.length !== 0 && uploadMRef.value) { uploadMRef.value.fileListRefresh(mermorandumPath) }
      }
      loadPath()
    })
    const initInfo = () => {
      state.companyAddressAll = [{ 'key': i18n.global.t('pages_merchant_add_058'), 'value': '0' }, { 'key': i18n.global.t('pages_merchant_add_059'), 'value': '1' }, { 'key': i18n.global.t('pages_merchant_add_060'), 'value': '2' }]
    }
    const loadPath = () => {
      if (state.modelForm.isParentCertificate === '1') {
        nextTick(() => {
          const { ownerShipPath, parentCertificatePath } = state.modelForm
          if (ownerShipPath.length !== 0 && uploadORef.value) uploadORef.value.fileListRefresh(ownerShipPath)
          if (parentCertificatePath.length !== 0 && uploadPRef.value) uploadPRef.value.fileListRefresh(parentCertificatePath)
        })
      }
    }
    const handleEdit = (currentIndex) => {
      if (currentIndex) state.modelForm.parentCertificate = state.modelForm.parentCertificate.filter((item, index) => index !== currentIndex)
      else state.modelForm.parentCertificate.push({ country: null, name: '' })
    }
    const handleSonEdit = (currentIndex) => {
      if (currentIndex) state.modelForm.resides = state.modelForm.resides.filter((item, index) => index !== currentIndex)
      else state.modelForm.resides.push({ country: null, name: '' })
    }
    const dateChange = (e, name) => {
      state.modelForm[name] = e
      if (name === 'establishDate') {
        state.yearStatus = isOneYearPassed(e)
      }
    }
    const isOneYearPassed = (startDate) => {
      const currentDate = new Date()// 当前日期
      const start = new Date(startDate) // 成立日期
      const timeDifference = currentDate - start // 计算日期差距 — 差值单位是毫秒
      const yearsDifference = timeDifference / (1000 * 3600 * 24 * 365)// 将差距转换为年份（365天为一年）
      return yearsDifference >= 1 // 判断是否已超过1年
    }
    initInfo()
    const businessOcrFun = async(img) => { // 营业执照OCR
      state.txtSpin = true
      const res = await _user.imgUrlBusinessOcr(img.join(','))
      if (res.data.succ) {
        const info = res.data.data[0]
        state.modelForm.businessLicenseCertificateNumber = info.regNum
        state.modelForm.merchantName = info.company
        state.modelForm.establishDate = info.establishDate
        state.modelForm.brExpiryDate = info.validDate
        state.modelForm.isBrExpiry = false
        state.modelForm.registrationAddress = info.address
        if (state.modelForm.isAddress) {
          state.modelForm.mailingAddress = info.address
        }
        state.modelForm.merchantNameEn = ''
        if (info.company) {
          const nameCN = await _user.chineseToPinYin(info.company)
          if (nameCN.data.succ) {
            state.modelForm.merchantNameEn = nameCN.data.data
          }
        }
      }
      state.txtSpin = false
    }
    const parameChange = (val, name) => {
      state.modelForm[name] = val
    }
    const addressReg = (e) => {
      if (state.modelForm.isAddress) {
        state.modelForm.mailingAddress = state.modelForm.registrationAddress
      }
    }
    const downOwnerPath = async (name) => {
      const res = await _user.exportMerchantTemplate({ name })
      if (res.data.succ) window.open(res.data.data)
    }
    return {
      uploadBRef,
      uploadAddressBRef,
      uploadBRCRef,
      uploadIRef,
      uploadNNRef,
      uploadNARef,
      uploadMRef,
      uploadBRRef,
      uploadORef,
      uploadPRef,
      submit,
      lastBut,
      initInfo,
      loadPath,
      handleEdit,
      handleSonEdit,
      isOneYearPassed,
      dateChange,
      businessOcrFun,
      parameChange,
      addressReg,
      downOwnerPath,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
.business-info {
    display: flex;
    justify-content: space-around;
    width: 90%;
    margin: auto;
    .cp_tip {
        position: relative;
        border: 1px solid #DFDFDF;
        border-radius: 10px;
        padding: 20px;
        color: #333;
        line-height: 33px;
        font-size: 14px;
        max-width: 600px;
        min-width: 430px;
        /deep/.ant-form-item-label{
          padding-bottom: 2px !important;
        }
    }
    .ant-form-item{
      margin-bottom: 5px !important;
      /deep/.ant-input-lg{
        font-size: 14px !important;
      }
    }

  .bi_input{
    border-radius: 5px !important;
    font-size: 15px !important;
    padding-top: 3px;
    padding-bottom: 3px;
    /deep/.ant-select-selector{
      border-radius: 5px !important;
    }
  }
}

.bi_upload{
  max-width: 420px !important;
  /deep/.ant-upload{
    padding: 2px 0px  5px 0px !important;
  }
  /deep/.ant-upload-img{
    margin-bottom: 1px !important;
  }
  /deep/.ant-upload-hint{
    margin-top: 2px !important;
  }
}
.explain {
  color: #495060;
}
.bi-country-area {
  margin-top: 10px;
  width: 330px;
  margin-right: 20px;
  .edit-area {
    text-align: right;
    padding-right: 12px;
    margin-top: -6px;
    .ic-delete, .ic-add  {
      color: @main-color;
      cursor: pointer;
      font-size: 20px;
    }
  }
}
.bi_area_box{
  max-width: 1060px !important;
}
.bi_right{
  margin-right: 8%;
}
.ci_spin{
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 99;
  background-color: rgba(255,255,255,0.4);
}
.error-area {
    padding: 5px 10px 5px 12px;
    border-radius: 5px;
    color: @error-color;
    background: #fef0f0;
    margin-top: 5px;
    text-align: left;
    word-break: break-word;
    .ic-error {
      font-size: 16px;
      margin-right: 12px;
    }
    }
</style>
<style lang="css" scoped>
@media screen and (max-width: 768px) {
  .cp_tip{
    min-width: 380px !important;
  }
  .bi_right{
    margin-right: 0% !important;
  }
  .bi-country-area{
    width: 100% !important;
    margin-right: 0px !important;
  }
}
.cp_content_box{
  min-width: 1000px !important;
}
</style>
